import {
  Button,
  Divider,
  Flex,
  Stack,
  Text,
  Link as ChakraLink,
  Image
} from '@chakra-ui/react'
import { useNavigate, Navigate, Link } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Input } from '../components/Form/Input'
import { SignInFormSchema } from './schemas'
import { AuthResponse, SignInCredentials, SignInFormData } from './types/index'
import { toast } from 'react-toastify'
import { jwtDecode, useAuth } from '../contexts/AuthContext'
import { api } from '../services/axios'
import LogoRNAcolhe from '../assets/logo.png'
import LogoSethas from '../assets/logo_sethasrn.png'

export function SignIn() {
  const { signIn, isAuthenticated } = useAuth()
  const navigateTo = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignInFormData>({
    resolver: yupResolver(SignInFormSchema),
  })

  const handleSignIn: SubmitHandler<SignInFormData> = async (values, event) => {
    event?.preventDefault()
    await new Promise(resolve => setTimeout(resolve, 2000))

    try {
      const credentials = {
        username: values.cpf,
        password: values.password,
      } as SignInCredentials

      const { data } = await api.post('login/', credentials)
      const { access, refresh } = data as AuthResponse

      const { agente_id } = jwtDecode(access)

      if (agente_id) {
        signIn(access, refresh)
        toast.success('Login realizado com sucesso!')
        navigateTo('/requests')
      } else {
        toast.error('Você não tem permissão para executar essa ação!')
      }
    } catch (loginRequestError: any) {
      const { detail } = loginRequestError?.response.data
      if (detail) {
        toast.error(`${detail}`)
      } else {
        toast.error('Usuário e/ou senha incorreto(s)')
      }
    }
  }

  // previne a renderização do componente
  if (isAuthenticated) {
    return <Navigate to="/requests" replace={true} />
  }
  return (
    <Flex w={'100vw'} h={'100vh'} align={'center'} justify={'center'}>
      <title>RN-ACOLHE - Login</title>
      <Flex
        as={'form'}
        w={'100%'}
        maxW={'360px'}
        bg={'platinum.100'}
        padding={'8'}
        borderRadius={'8'}
        flexDir={'column'}
        onSubmit={handleSubmit(handleSignIn)}
      >
        <Flex align={'center'} flexDir={'column'}>
          <Image h="4rem" src={LogoRNAcolhe} alt="Logo do RN Acolhe" />

          <Divider my={'2'} borderColor={'gray.700'} />

          <Text fontSize={'lg'} fontWeight={'bold'} color={'#1F191A'}>
            Agente SETHAS
          </Text>
        </Flex>

        <Stack spacing={4}>
          <Input
            label={'CPF'}
            _required={false}
            type={'text'}
            mask={'999.999.999-99'}
            placeholder="000.000.000-00"
            error={errors.cpf}
            {...register('cpf')}
          />

          <Input
            label={'Senha'}
            _required={false}
            type={'password'}
            error={errors.password}
            {...register('password')}
          />

          <Button
            type={'submit'}
            marginTop={6}
            bgColor={'blue.50'}
            _hover={{ bgColor: 'blue.200' }}
            color={'platinum.50'}
            size={'md'}
            isLoading={isSubmitting}
            loadingText={'Entrando'}
          >
            Entrar
          </Button>

          <Flex
            flexDirection="column"
            justifyContent="space-between"
            gap="4"
            mb="2"
          >
            <Link to="redefinepassword">
              <ChakraLink
                display="flex"
                alignItems="center"
                justifyContent="center"
                color={'blue.50'}
              >
                Esqueceu a senha?
              </ChakraLink>
            </Link>
          </Flex>
        </Stack>

        <Divider my={'4'} borderColor={'gray.700'} />

        <Flex align={'center'} justify={'center'}>
          <Image
            h="4rem"
            src={LogoSethas}
            alt="Logo da Secretaria de Estado do Trabalho, da Habitação e da Assistência Social (SETHAS)"
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
