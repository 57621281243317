import { api } from "../../../services/axios"
import { useQuery } from 'react-query'


interface Municipio {
    nome: string
    cod_ibge: number
}

interface AgenteCras {
    id: number
    nome: string
    cpf: string
    nome_profissao: string
    municipios: Municipio[]
}

export interface Iresponse{
    results: AgenteCras[]
    count: number
}

const getAgentCras = async (page: number, registerPerPage: number, agenteId?: number): Promise<Iresponse> =>{
    const {data:{results, count}} = await api.get<Iresponse>(`agentes/${agenteId}/agentescras/?limit=${registerPerPage}&offset=${(page - 1) * registerPerPage}`)
    return {results, count}
}

export const useAgentCras = (page: number, registerPerPage: number, agenteId?: number) =>{
    return useQuery(['agentecras', page], () => getAgentCras(page, registerPerPage, agenteId),{
        staleTime: 1000 * 60 * 5,
    })  
}
