import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  ModalBody,
  Text,
  ModalFooter,
  Button
} from '@chakra-ui/react'

interface ConfirmRequestBindingProps {
  onConfirm: () => void
  onClose: () => void
  isOpen: boolean
}

export function ConfirmRequestBinding({
  isOpen,
  onClose,
  onConfirm
}: ConfirmRequestBindingProps) {

  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="none"
      size={'sm'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={['center']}>Confirmar vinculação</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign={['center']}>
          <Text>
            Você tem certeza que deseja confirmar a vinculação da solicitação?
          </Text>
        </ModalBody>
        <ModalFooter justifyContent={['center']} gap="4">
        <Button
            size={'md'}
            color={'platinum.50'}
            bgColor={'platinum.600'}
            onClick={onClose}
          >
            Cancelar
          </Button>

          <Button
            size={'md'}
            color={'platinum.50'}
            bgColor={'green.50'}
            _hover={{ bgColor: 'green.100' }}
            onClick={onConfirm}
            >
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
