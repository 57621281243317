import { useState } from 'react'
import { Flex, Heading, Spinner, SimpleGrid } from '@chakra-ui/react'

import { AlertComponent } from '../../components/Alert'
import { BreadCrumb } from '../../components/BreadCrumb'
import { Content } from '../../components/Content'
import { useRequests } from './hook'
import { Pagination } from '../../components/Pagination'
import { CardRequest } from '../../components/Cards/Request'
import { Subtitles } from '../../components/Subtitles'

export function RequestList() {
  const [page, setPage] = useState(1)
  const perPage = 9

  const { isLoading, isFetching, data, error } = useRequests(page, perPage)

  function showPagination(count: number) {
    return count > 9 && perPage === 9
  }

  return (
    <Content>
      <title>RN-ACOLHE - Solicitações cadastradas</title>
      <BreadCrumb currentPath="Solicitações cadastradas" />
      <Flex mb={'8'} justify={'space-between'} align={'center'}>
        <Heading size={'lg'} fontWeight={'normal'}>
          Solicitações cadastradas
          {!isLoading && isFetching && (
            <Spinner size={'sm'} color={'blue.300'} ml={'4'} />
          )}
        </Heading>
      </Flex>
      {isLoading ? (
        <Flex color={'blue.300'} justify={'center'} align={'center'}>
          <Spinner />
        </Flex>
      ) : error ? (
        <AlertComponent
          status={'error'}
          title={'Ocorreu um erro'}
          description={`Não foi possível listar as solicitações.`}
        />
      ) : !data?.results.length ? (
        <AlertComponent
          status={'warning'}
          title={'Nenhuma solicitação encontrada'}
          description={`Não encontramos nenhuma solicitação cadastrada.`}
        />
      ) : (
        <>
          <Subtitles subtitlesIdFilter={[2]} />
          <SimpleGrid flex={'1'} gap={'4'} minChildWidth={'320px'}>
            {data?.results.map(request => (
              <CardRequest key={request.id} request={request} />
            ))}
          </SimpleGrid>

          {data?.results.length !== undefined && data?.results.length !== 0 && (
            <>
              {showPagination(data.count) && (
                <Pagination
                  totalCountOfRegisters={data.count}
                  registersPerPage={perPage}
                  currentPage={page}
                  onPageChange={setPage}
                  titlePage={'solicitações'}
                />
              )}
            </>
          )}
        </>
      )}
    </Content>
  )
}
