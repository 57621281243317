import * as yup from 'yup'

export const CreateAgentCrasFormSchema = yup.object().shape({
  logradouro: yup
    .string()
    .required('Logradouro é obrigatório')
    .max(50, 'Logradouro deve ter no máximo 50 caracteres'),
  numero: yup
    .string()
    .required('Número é obrigatório')
    .min(1, 'Número deve ter no mínimo 1 caracteres')
    .max(30, 'Número deve ter no máximo 30 caracteres'),
  bairro: yup
    .string()
    .required('Bairro é obrigatório')
    .min(2, 'Bairro deve ter no mínimo 3 caracteres')
    .max(30, 'Bairro deve ter no máximo 30 caracteres'),
  complemento: yup
    .string()
    .max(50, 'Complemento deve ter no máximo 3 caracteres')
    .optional(),
  ponto_de_referencia: yup
    .string()
    .max(50, 'Ponto de referência deve ter no máximo 3 caracteres')
    .optional(),
  uf: yup.string().default('RN'),
  cep: yup
    .string()
    .required('CEP é obrigatório')
    .transform((value) => value.replace(/\D/g, ''))
    .matches(/^[0-9]+$/, 'CEP deve conter apenas números')
    .min(8, 'CEP deve ter no mínimo 8 caracteres')
    .max(8, 'CEP deve ter no máximo 8 caracteres'),
  municipio: yup
    .string()
    .required('Município é obrigatório')
    .transform((value) => value.replace(/\D/g, '')),
  nome: yup
    .string()
    .required('Nome completo é obrigatório')
    .min(2, 'Nome deve ter no mínimo 2 caracteres')
    .max(100, 'Nome deve ter no máximo 100 caracteres'),
  data_nascimento: yup.string().required('Data nascimento é obrigatória'),
  cpf: yup
    .string()
    .required('CPF é obrigatório')
    .transform((value) => value.replace(/\D/g, ''))
    .matches(/^[0-9]+$/, 'CPF deve conter apenas números')
    .min(11, 'CPF deve ter no mínimo 11 caracteres')
    .max(11, 'CPF deve ter no máximo 11 caracteres'),
  rg: yup
    .string()
    .required('RG é obrigatório')
    .transform((value) => value.replace(/\D/g, ''))
    .matches(/^[0-9]+$/, 'RG deve conter apenas números')
    .min(5, 'RG deve ter no mínimo 5 caracteres')
    .max(9, 'RG deve ter no máximo 9 caracteres'),
  data_expedicao: yup.string().required('Data de expedição é obrigatória'),
  orgao_expedidor: yup
    .string()
    .required('Órgão expedidor do RG é obrigatório')
    .max(30, 'Órgão expedidor deve ter no máximo 30 caracteres'),
  uf_orgao_expedidor: yup
    .string()
    .required('UF do órgão expedidor de RG é obrigatório')
    .matches(/^[A-Z]{2}$/, 'UF deve conter apenas 2 caracteres'),
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
  telefone: yup
    .string()
    .required('Telefone é obrigatório')
    .transform((value) => value.replace(/[^0-9]/g, ''))
    .min(9, 'Telefone deve ter no mínimo 9 caracteres')
    .max(11, 'Telefone deve ter no máximo 11 caracteres'),
  telefone_alternativo: yup
    .string()
    .transform((value) => value.replace(/[^0-9]/g, ''))
    .optional(),
  nome_profissao: yup
    .string()
    .required('Nome da profissão é obrigatório')
    .min(2, 'Nome deve ter no mínimo 2 caracteres')
    .max(100, 'Nome deve ter no máximo 100 caracteres'),
  profissao: yup.string().when('nome_profissao', {
    is: 'outros',
    then: yup
      .string()
      .required('Nome da profissão é obrigatório')
      .min(2, 'Nome deve ter no mínimo 2 caracteres')
      .max(100, 'Nome deve ter no máximo 100 caracteres'),
  }),
  numero_registro: yup
    .string()
    .transform((value) => value.replace(/\D/g, ''))
    .required('Número de registro é obrigatório')
    .max(50, 'Número de registro deve ter no máximo 50 caracteres'),
  municipios: yup
    .array()
    .nullable()
    .required('Município(s) de atuação é(são) obrigatório(s)')
    .min(1, 'Selecione no mínimo 1 município de atuação'),
  sexo: yup.string().required('Sexo é obrigatório'),
  password: yup
    .string()
    .required('Senha é obrigatória')
    .min(8, 'Senha deve ter no mínimo 8 caracteres')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*()+.,;?{}^><:-])[A-Za-z\d!@#$%&*()+.,;?{}^><:-]{8,}$/,
      'Senha deve ter letras maiúsculas, minúsculas, números e caracteres especiais (!@#$%&*()+.,;?{}^><:-)',
    )
    .trim(),
  confirmation_password: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Senhas não conferem'),
})
