import { useForm } from 'react-hook-form'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react'
import { Input } from '../Form/Input'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

interface IPropsJustifyModal {
  isOpen: boolean
  confirm: (justify: string) => void
  close: () => void
}

interface IForm {
  justificativa: string
}

const InvalidateModalSchema = Yup.object().shape({
  justificativa: Yup.string()
    .required('Justificativa é obrigatória')
    .min(10, 'Tamanho mínimo de 10 caracteres')
    .max(280, 'Tamanho máximo de 280 caracteres')
    .trim(),
})

export function JustifyModal({ isOpen, confirm, close }: IPropsJustifyModal) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IForm>({ resolver: yupResolver(InvalidateModalSchema) })

  const submit = (data: { justificativa: string }) => {
    confirm(data.justificativa)
    reset()
  }

  const handleCloseJustifyModal = () => {
    reset()
    close()
  }

  return (
    <>
      <Modal
        isCentered
        onClose={handleCloseJustifyModal}
        isOpen={isOpen}
        motionPreset="none"
        size={'lg'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent as={'form'} onSubmit={handleSubmit(submit)}>
          <ModalHeader textAlign={['center']}>
            Confirmar invalidação
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              label="Justificativa de invalidação do documento"
              as={'textarea'}
              type={'text'}
              placeholder={'Insira a justificativa...'}
              {...register('justificativa')}
              error={errors.justificativa}
              minH={'130px'}
              size={'md'}
              px="4"
              py="2"
            />
          </ModalBody>

          <ModalFooter justifyContent={['center']} gap="4">
            <Button
              size={'md'}
              color={'platinum.50'}
              bgColor={'platinum.600'}
              type="button"
              onClick={handleCloseJustifyModal}
            >
              Cancelar
            </Button>

            <Button
              size={'md'}
              color={'platinum.50'}
              bgColor={'green.50'}
              _hover={{ bgColor: 'green.100' }}
              type="submit"
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
