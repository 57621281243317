import { Avatar, Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react'
import { useAuth } from '../../contexts/AuthContext'
import { addFormattingForCPF } from './utils'

interface ProfileProps {
  showProfileData?: boolean
}

export function Profile({ showProfileData }: ProfileProps) {
  const { user } = useAuth()

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex align={'center'}>
      {showProfileData && (
        <Box mr={'4'} textAlign={'right'}>
          <Text color={'platinum.50'} fontWeight={'semibold'}>
            {user && user.nome}
          </Text>
          <Text color={'platinum.50'} fontSize={'small'}>
            CPF: {user && addFormattingForCPF(user.cpf)}
          </Text>
        </Box>
      )}

      <Avatar
        size={isWideVersion ? 'md' : 'sm'}
        name={user && user.nome}
        bgColor={'green.50'}
        color={'platinum.50'}
      />
    </Flex>
  )
}
