interface District {
  id: number
  name: string
  acronym: string
}

const districts: District[] = [
  {
    id: 24,
    acronym: 'RN',
    name: 'Rio Grande do Norte'
  },
  {
    id: 12,
    acronym: 'AC',
    name: 'Acre'
  },
  {
    id: 27,
    acronym: 'AL',
    name: 'Alagoas'
  },
  {
    id: 16,
    acronym: 'AP',
    name: 'Amapá'
  },
  {
    id: 13,
    acronym: 'AM',
    name: 'Amazonas'
  },
  {
    id: 29,
    acronym: 'BA',
    name: 'Bahia'
  },
  {
    id: 23,
    acronym: 'CE',
    name: 'Ceará'
  },
  {
    id: 53,
    acronym: 'DF',
    name: 'Distrito Federal'
  },
  {
    id: 32,
    acronym: 'ES',
    name: 'Espírito Santo'
  },
  {
    id: 52,
    acronym: 'GO',
    name: 'Goiás'
  },
  {
    id: 21,
    acronym: 'MA',
    name: 'Maranhão'
  },
  {
    id: 51,
    acronym: 'MT',
    name: 'Mato Grosso'
  },
  {
    id: 50,
    acronym: 'MS',
    name: 'Mato Grosso do Sul'
  },
  {
    id: 31,
    acronym: 'MG',
    name: 'Minas Gerais'
  },
  {
    id: 15,
    acronym: 'PA',
    name: 'Pará'
  },
  {
    id: 25,
    acronym: 'PB',
    name: 'Paraíba'
  },
  {
    id: 41,
    acronym: 'PR',
    name: 'Paraná'
  },
  {
    id: 26,
    acronym: 'PE',
    name: 'Pernambuco'
  },
  {
    id: 22,
    acronym: 'PI',
    name: 'Piauí'
  },
  {
    id: 33,
    acronym: 'RJ',
    name: 'Rio de Janeiro'
  },
  {
    id: 43,
    acronym: 'RS',
    name: 'Rio Grande do Sul'
  },
  {
    id: 11,
    acronym: 'RO',
    name: 'Rondônia'
  },
  {
    id: 14,
    acronym: 'RR',
    name: 'Roraima'
  },
  {
    id: 42,
    acronym: 'SC',
    name: 'Santa Catarina'
  },
  {
    id: 35,
    acronym: 'SP',
    name: 'São Paulo'
  },
  {
    id: 28,
    acronym: 'SE',
    name: 'Sergipe'
  },
  {
    id: 17,
    acronym: 'TO',
    name: 'Tocantins'
  }
]

export const useDistricts = () => districts

export const getDistricts = () => districts