import { ListItem, Badge, Text } from '@chakra-ui/react'

interface SubtitleProps {
  name: string
  info: string
  bgColor: string
}

export function Subtitle({
  name,
  info,
  bgColor
}: SubtitleProps) {
  return (
    <ListItem>
      <Text fontSize="sm">
        <Badge rounded="full" mr="1" bg={`${bgColor}`} color={'white'}>
          {name}
        </Badge>
        {info}
      </Text>
    </ListItem>
  )
}
