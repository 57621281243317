import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  SimpleGrid,
  Divider,
  Heading,
  Icon,
  Link as ChakraLink,
} from '@chakra-ui/react'
import { Input } from '../Form/Input'
import { AiFillCloseCircle, AiFillCheckCircle } from 'react-icons/ai'
import { IRequest } from '../../interfaces/RequestInterface'
import { CardLinkedRequest } from '../Cards/LinkedRequest'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { api } from '../../services/axios'

interface IPropsJustificationModal {
  isOpen: boolean
  onClose: () => void
  requestData: IRequest
}

export function JustificationModal({
  isOpen,
  onClose,
  requestData,
}: IPropsJustificationModal) {
  const pathUrl = api.defaults.baseURL?.replace('/api/', '')

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="none"
        size={'xl'}
        closeOnOverlayClick={false}
        scrollBehavior={'inside'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Parecer da Avaliação</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={'8'}>
              <SimpleGrid minChildWidth={'244px'} w={'100%'}>
                <CardLinkedRequest request={requestData} />
              </SimpleGrid>
              <Divider borderColor={'gray.700'} />
              <Heading size={'md'}>Parecer geral da solicitação</Heading>
              <SimpleGrid
                minChildWidth={'244px'}
                spacing={['6', '8']}
                w={'100%'}
              >
                <Input
                  name="parecer"
                  as={'textarea'}
                  type={'text'}
                  variant={'unstyled'}
                  value={requestData.parecer}
                  isReadOnly
                  minH={'130px'}
                  textAlign={'justify'}
                  size={'md'}
                  px="4"
                  py="2"
                />
              </SimpleGrid>
              {requestData.anexos && (
                <>
                  <Divider borderColor={'gray.700'} />
                  <Heading size={'md'}>Documentos </Heading>
                  {requestData.anexos.map(
                    ({ id, tipo, justificativa, arquivo, valido }) => (
                      <VStack align="stretch" w={'100%'} key={id}>
                        <Heading size={'sm'} mb={3}>
                          <Icon
                            as={!valido ? AiFillCloseCircle : AiFillCheckCircle}
                            color={!valido ? 'red.500' : 'green.500'}
                            mr={1}
                            w={4}
                            h={4}
                            verticalAlign={'middle'}
                          />
                          {tipo}
                        </Heading>
                        <ChakraLink
                          href={`${pathUrl}${arquivo}`}
                          isExternal
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent="center"
                          minWidth="fit-content"
                          rounded="md"
                          bgColor={'yellow.700'}
                          color={'platinum.50'}
                          px="4"
                          py="2"
                        >
                          <Icon as={ExternalLinkIcon} mr={2} />
                          Visualizar documento
                        </ChakraLink>
                        {justificativa && (
                          <Input
                            name={`documento-${id}`}
                            as={'textarea'}
                            type={'text'}
                            variant={'unstyled'}
                            value={justificativa}
                            isReadOnly
                            height={'150px'}
                            textAlign={'justify'}
                            size={'md'}
                            p={'3'}
                          />
                        )}
                        <Divider py={'2'} borderColor={'gray.700'} />
                      </VStack>
                    ),
                  )}
                </>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              size={'md'}
              color={'platinum.50'}
              onClick={onClose}
            >
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
