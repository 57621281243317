import { Flex } from '@chakra-ui/react'
import { BaseCard } from '../BaseCard'

interface Institution {
  id: number
  nome: string
  municipio: string
}

interface CardInstitutionProps {
  id: number
  institution: Institution
}

export function CardInstitutions({ id, institution }: CardInstitutionProps) {
  return (
    <BaseCard title={institution.nome.toUpperCase()} subtitle={institution.municipio}>
      <Flex justify={'flex-end'} alignItems={'center'} gap={'2rem'}>
      </Flex>
    </BaseCard>
  )
}
