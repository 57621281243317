import {
  Badge,
  Box,
  Flex,
  Stack,
  Text,
  Button,
  Icon,
  useBreakpointValue,
  useDisclosure,
  Divider,
} from '@chakra-ui/react'

import { toast } from 'react-toastify'
import { RiFileAddLine } from 'react-icons/ri'
import { useBgColorStatus } from '../../hooks/useBgColorStatus'
import { useAuth } from '../../../contexts/AuthContext'
import { queryClient } from '../../../services/queryClient'
import { api } from '../../../services/axios'
import { ConfirmRequestBinding } from '../../Modals/ConfirmRequestBinding'
import { addFormattingForDate } from '../../../utils'

interface Request {
  id: number
  nome: string
  municipio: string
  status: string
  created_at: string
}

interface CardRequestProps {
  request: Request
}

export function CardRequest({ request }: CardRequestProps) {
  const { user } = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const createAt = addFormattingForDate(request.created_at)
  const bgColorStatus = useBgColorStatus(request.status)

  function statusMustBeRegistered() {
    return request.status === 'CADASTRADA'
  }

  async function onConfirmRequestBinding() {
    const agenteId = user && user.agente_id
    if (!agenteId) {
      toast.error('Não foi possível vincular a solicitação!')
      onClose()
      return
    }
    toast.info('Vinculando solicitação...', {
      autoClose: 1000, // 1 second
    })

    await new Promise(resolve => setTimeout(resolve, 1500)) // wait 1.5 seconds
    try {
      const RequestId = request.id
      const { data } = await api.patch(`solicitacoes/${RequestId}/vincular/`, {
        avaliador: agenteId,
        status: 3,
      })
      toast.success(`${data.mensagem}`)
      queryClient.invalidateQueries('requests')
      queryClient.invalidateQueries('linked')
      onClose()
    } catch (errorRequestBinding: any) {
      const { detail } = errorRequestBinding?.response.data
      if (detail) {
        toast.error(`${detail}`)
        queryClient.invalidateQueries('requests')
      } else {
        toast.error('Não foi possível vincular a solicitação!')
      }
      onClose()
    }
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  })

  return (
    <>
      <ConfirmRequestBinding
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onConfirmRequestBinding}
      />

      <Box
        rounded={'8'}
        bgColor={'platinum.100'}
        overflow={'hidden'}
        boxShadow={'2px 2px 4px rgba(0, 0, 0, 0.25)'}
      >
        <Box p={4}>
          <Stack align={'flex-start'}>
            <Text as="h2" fontWeight="bold" color={'blue.500'}>
              {request.nome}
            </Text>
            <Text fontWeight="light">Município: {request.municipio}</Text>
            <Text fontWeight="light">Solicitado em: {createAt}</Text>
          </Stack>

          <Divider my={'2'} borderColor={'gray.700'} />

          <Flex
            flexFlow={'wrap'}
            flexDirection={isWideVersion ? 'row' : 'column'}
            alignItems={isWideVersion ? 'center' : 'flex-start'}
            justifyContent={'space-between'}
            mt={'2'}
            gap={'2'}
          >
            <Badge
              variant="solid"
              rounded="full"
              py="2"
              px="3"
              bgColor={bgColorStatus}
            >
              {request.status}
            </Badge>

            {statusMustBeRegistered() && (
              <Button
                variant={'solid'}
                size="sm"
                minWidth="fit-content"
                color={'platinum.50'}
                bgColor={'green.50'}
                _hover={{ bgColor: 'green.100' }}
                leftIcon={<Icon as={RiFileAddLine} fontSize={'16'} />}
                onClick={() => onOpen()}
              >
                Vincular solicitação
              </Button>
            )}
          </Flex>
        </Box>
      </Box>
    </>
  )
}
