import {
  Badge,
  Box,
  Flex,
  Stack,
  Text,
  Button,
  Icon,
  useBreakpointValue,
  Divider,
  useDisclosure,
} from '@chakra-ui/react'
import { RiFileSearchLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { addFormattingForDate, StatusTypes } from '../../../utils'
import { useBgColorStatus } from '../../hooks/useBgColorStatus'
import { IRequest } from '../../../interfaces/RequestInterface'
import { AiOutlineFolderView } from 'react-icons/ai'
import { JustificationModal } from '../../Modals/JustificationModal'
interface CardLinkedRequestProps {
  request: IRequest
  hasJustificationModal?: boolean
}

export function CardLinkedRequest({
  request,
  hasJustificationModal = false,
}: CardLinkedRequestProps) {
  const createAt = addFormattingForDate(request.created_at)
  const bgColorStatus = useBgColorStatus(request.status)
  const { isOpen, onOpen, onClose } = useDisclosure()


  function statusMustBeUnderReview() {
    return (
      request.status === StatusTypes.EM_ANALISE ||
      request.status === StatusTypes.CORRIGIDA
    )
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  })

  return (
    <>
      <JustificationModal
        isOpen={isOpen}
        onClose={onClose}
        requestData={request}
      />
      <Box
        rounded={'8'}
        bgColor={'platinum.100'}
        overflow={'hidden'}
        boxShadow={'2px 2px 4px rgba(0, 0, 0, 0.25)'}
      >
        <Box p={4}>
          <Stack align={'flex-start'}>
            <Text as="h2" fontWeight="bold" color={'blue.500'}>
              {request.nome}
            </Text>
            <Text fontWeight="light">Município: {request.municipio}</Text>
            <Text fontWeight="light">Solicitado em: {createAt}</Text>
          </Stack>

          <Divider my={'2'} borderColor={'gray.700'} />

          <Flex
            flexFlow={'wrap'}
            flexDirection={isWideVersion ? 'row' : 'column'}
            alignItems={isWideVersion ? 'center' : 'flex-start'}
            justifyContent={'space-between'}
            mt={'2'}
            gap={'2'}
          >
            <Badge
              variant="solid"
              rounded="full"
              py="2"
              px="3"
              bgColor={bgColorStatus}
            >
              {request.status}
            </Badge>

            {statusMustBeUnderReview() && (
              <Link to={`/linked/${request.id}`}>
                <Button
                  as={'a'}
                  variant={'outline'}
                  borderColor="blue.100"
                  color={'blue.100'}
                  size="sm"
                  minWidth="fit-content"
                  bgColor={'platinum.50'}
                  _hover={{ bgColor: 'blue.300', color: 'platinum.50' }}
                  leftIcon={<Icon as={RiFileSearchLine} fontSize={'16'} />}
                >
                  Detalhar solicitação
                </Button>
              </Link>
            )}

            {request.anexos && hasJustificationModal && (
              <Button
                type="button"
                variant={'solid'}
                color={'platinum.50'}
                size={'sm'}
                minWidth="fit-content"
                bgColor={'blue.100'}
                _hover={{ bgColor: 'blue.300' }}
                leftIcon={<Icon as={AiOutlineFolderView} fontSize={'16'} />}
                onClick={onOpen}
              >
                Visualizar parecer
              </Button>
            )}
          </Flex>
        </Box>
      </Box>
    </>
  )
}
