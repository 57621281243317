import { Flex, Heading, Spinner } from '@chakra-ui/react'
import { BreadCrumb } from '../../components/BreadCrumb'
import { Content } from '../../components/Content'
import { EvaluateDocument } from '../../components/Request/EvaluateDocument'
import { useAuth } from '../../contexts/AuthContext'
import { useParams, useNavigate } from 'react-router-dom'
import { useDetailRequest } from './hook'
import { useCallback, useEffect, useState } from 'react'
import { Anexo, DetailRequest, Orfao } from './types'
import { api } from '../../services/axios'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { OrphanInfoCard } from '../../components/Cards/OrphanInfo'
import { AlertComponent } from '../../components/Alert'
import { FormDetailRequest } from '../../components/Form/FormDetailRequest'
import { ConfirmModal } from '../../components/Modals/ConfirmEvaluatedRequestModal'
import { ActionMenu } from '../../components/Menu/ActionMenu'
import { useLabels } from './hook/useLabels'
import { TabsComponent } from '../../components/Tabs/Tabs'
import { StatusTypes } from '../../utils'

export default function EvaluateRequest() {
  const pathUrl = api.defaults.baseURL?.replace('/api/', '')
  const { requestId } = useParams()
  const { user } = useAuth()
  const labels = useLabels()

  const [orphanInfo, setOrphanInfo] = useState<Orfao>({
    id: 0,
    sexo: '',
    fonte_beneficio: '',
    nome: '',
    cpf: '',
    nis: '',
    data_nascimento: '',
    recebe_beneficio: false,
    valor_beneficio: 0,
  })
  const [attachments, setAttachmentss] = useState<Anexo[]>([])
  const [statusRequest, setStatusRequest] = useState<string>('')
  const [requestAllData, setRequestAllData] = useState<DetailRequest>()
  const [evaluatedDocumentsCount, setEvaluatedDocumentsCount] =
    useState<number>(0)

  /* utilizado para pegar dinâmicamente a quantidade de arquivos anexados vindos na solicitação da api 
    e utilizar isso para servir de base pra lógica de liberar os botões de avaliação*/
  const [documentsCount, setDocumentsCount] = useState<number>(0)

  const [openIndeferirModal, setOpenIndeferirModal] = useState<boolean>(false)
  const [openDeferirModal, setOpenDeferirModal] = useState<boolean>(false)
  const [openCorrigirModal, setOpenCorrigirModal] = useState<boolean>(false)

  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const { data, isLoading, error } = useDetailRequest(
    user && user.agente_id,
    Number(requestId),
  )

  const checkStatusRequest = useCallback(
    (data: DetailRequest) => {
      const { status, orfao, anexos } = data
      if (
        status === StatusTypes.EM_ANALISE ||
        status === StatusTypes.CORRIGIDA
      ) {
        setOrphanInfo(orfao)
        setAttachmentss(anexos)
        setStatusRequest(status)
        setRequestAllData(data)
        setDocumentsCount(anexos.length)
      } else {
        navigate('/linked')
      }
    },
    [navigate],
  )

  useEffect(() => {
    if (data) {
      checkStatusRequest(data)
    }
  }, [data, navigate, checkStatusRequest])

  const evaluatedAttachmentsCheck = (attachments: Anexo[]) => {
    setEvaluatedDocumentsCount(0)
    attachments.forEach(attachment => {
      if (attachment.valido !== null) {
        setEvaluatedDocumentsCount(prevState => prevState + 1)
      }
    })
  }

  useEffect(() => {
    if (attachments) {
      evaluatedAttachmentsCheck(attachments)
    }
  }, [attachments])

  const handleWichModalClose = (statusId: number) => {
    if (statusId === 6) {
      toggleDeferirModal()
    } else if (statusId === 7) {
      toggleIndeferirModal()
    } else if (statusId === 4) {
      toggleCorrigirModal()
    }
  }

  const handleErrorsApi = (errors: any) => {
    Object.entries(errors).forEach(([_, value]) => {
      toast.error(`${value}`)
    })
  }

  const handleSubmitEvaluatedRequest = async (
    statusId: number,
    justification?: string,
  ) => {
    try {
      const obj: { status: number; parecer: string } = {
        status: statusId,
        parecer: justification ? justification : '',
      }

      handleWichModalClose(statusId)
      const { data } = await api.patch(
        `solicitacoes/${requestId}/avaliar/`,
        obj,
      )
      toast.success(`${data.mensagem}`)
      queryClient.removeQueries()
      navigate('/linked')
    } catch (error: any) {
      if (error?.response.status === 400 || error?.response.status === 404) {
        handleErrorsApi(error?.response.data)
      } else {
        toast.error('Ocorreu um erro ao avaliar a solicitação')
      }
    }
  }

  const handleValidateDocument = async (documentId: number) => {
    try {
      const obj: {
        valido: boolean
        justificativa: null | string
      } = {
        valido: true,
        justificativa: null,
      }
      await api.patch(`documentos/${documentId}/validar/`, obj)
      toast.success('O documento foi validado com sucesso!')
      queryClient.invalidateQueries('detailRequest')
    } catch (error: any) {
      if (error?.response.status === 400 || error?.response.status === 404) {
        handleErrorsApi(error?.response.data)
      } else {
        toast.error('Ocorreu um erro ao validar o documento')
      }
    }
  }

  const handleInvalidateDocument = async (
    documentId: number,
    justify: string,
  ) => {
    try {
      const obj: {
        valido: boolean
        justificativa: null | string
      } = {
        valido: false,
        justificativa: justify,
      }
      await api.patch(`documentos/${documentId}/validar/`, obj)
      toast.success('O documento foi invalidado com sucesso!')
      queryClient.invalidateQueries('detailRequest')
    } catch (error: any) {
      if (error?.response.status === 400 || error?.response.status === 404) {
        handleErrorsApi(error?.response.data)
      } else {
        toast.error('Ocorreu um erro ao invalidar o documento')
      }
    }
  }

  const errorAlert = () => {
    return (
      <AlertComponent
        status={'error'}
        title={'Ocorreu um erro'}
        description={`Não foi possível carregar a página de avaliação`}
      />
    )
  }

  const toggleDeferirModal = () => {
    setOpenDeferirModal(!openDeferirModal)
  }

  const toggleIndeferirModal = () => {
    setOpenIndeferirModal(!openIndeferirModal)
  }

  const toggleCorrigirModal = () => {
    setOpenCorrigirModal(!openCorrigirModal)
  }

  return (
    <Content>
      <title>RN-ACOLHE - Avaliar solicitação</title>

      <BreadCrumb
        PreviousPaths={[{ name: 'Solicitações vinculadas', url: '/linked' }]}
        currentPath="Avaliar solicitação"
      />
      
      <Heading size={'lg'} fontWeight={'normal'} mb="8">
        Avaliar solicitação
      </Heading>
      
      {isLoading ? (
        <Flex color={'blue.300'} justify={'center'} align={'center'}>
          <Spinner />
        </Flex>
      ) : error ? (
        errorAlert()
      ) : (
        <>
          <ConfirmModal
            statusId={6}
            keyMsg={'DEFERIR'}
            hasJustification
            submitEvaluatedRequest={handleSubmitEvaluatedRequest}
            isOpen={openDeferirModal}
            toggle={toggleDeferirModal}
            title={'Confirmar Deferimento'}
            msg={
              'Você tem certeza que deseja deferir a solicitação? A seguinte ação não pode ser desfeita após a confirmação'
            }
          />
          <ConfirmModal
            statusId={7}
            keyMsg={'INDEFERIR'}
            hasJustification
            submitEvaluatedRequest={handleSubmitEvaluatedRequest}
            isOpen={openIndeferirModal}
            toggle={toggleIndeferirModal}
            title={'Confirmar Indeferimento'}
            msg={
              'Você tem certeza que deseja indeferir a solicitação? A seguinte ação não pode ser desfeita após a confirmação'
            }
          />
          <ConfirmModal
            statusId={4}
            keyMsg={'CORREÇÃO'}
            hasJustification
            submitEvaluatedRequest={handleSubmitEvaluatedRequest}
            isOpen={openCorrigirModal}
            toggle={toggleCorrigirModal}
            title={'Confirmar solicitação de correção'}
            msg={
              'Você tem certeza que deseja solicitar a correção da solicitação? A seguinte ação não pode ser desfeita após a confirmação'
            }
          />
          <OrphanInfoCard
            name={orphanInfo?.nome}
            cpf={orphanInfo?.cpf}
            status={statusRequest}
          />
          <TabsComponent
            tabsHeader={['Dados', 'Documentos']}
            tabsContent={[
              requestAllData && (
                <FormDetailRequest dataRequest={requestAllData} />
              ),
              attachments.length &&
                attachments?.map(attachment => (
                  <EvaluateDocument
                    id={attachment.id}
                    key={attachment.id}
                    label={labels[attachment.tipo - 1]}
                    fileLink={pathUrl + attachment.arquivo}
                    valid={attachment.valido}
                    validateDocument={handleValidateDocument}
                    invalidateDocument={handleInvalidateDocument}
                  />
                )),
            ]}
          />
          <ActionMenu
            evaluatedDocumentsCount={evaluatedDocumentsCount}
            baseDocumentsCount={documentsCount}
            toggleDeferirModal={toggleDeferirModal}
            toggleIndeferirModal={toggleIndeferirModal}
            toggleCorrigirModal={toggleCorrigirModal}
          />
        </>
      )}
    </Content>
  )
}
