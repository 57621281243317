import { Heading, SimpleGrid, VStack, Divider } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import {
  DetailRequest,
  Falecido,
  Instituicao,
  Tutor,
} from '../../../pages/EvaluateRequest/types'
import { Input } from '../Input'
import { useCallback, useEffect } from 'react'
import {
  addFormattingForCertificate,
  addFormattingForCPF,
  addFormattingForNIS,
  addFormattingForPhone,
  addFormattingPrice,
  addFormattingForRG,
  addFormattingForCNPJ,
} from '../../../utils'

type IProps = {
  dataRequest: DetailRequest
}

export function FormDetailRequest(data: IProps) {
  const { register, setValue, watch } = useForm<DetailRequest>()
  const tipo_da_orfandade = watch('tipo_orfandade')
  const orfao_beneficio = watch('orfao.recebe_beneficio')
  const falecido_um_beneficio = watch('falecido_um.recebia_beneficio')
  const falecido_dois_beneficio = watch('falecido_dois.recebia_beneficio')
  const has_tutor = watch('tutor_responsavel')
  const has_institution = watch('instituicao_responsavel')

  const deceasedTwoCheck = useCallback(
    (falecido_dois: Falecido) => {
      if (falecido_dois) {
        setValue('falecido_dois', {
          id: falecido_dois.id,
          nome: falecido_dois.nome,
          cpf: addFormattingForCPF(falecido_dois.cpf),
          numero_certidao_obito: addFormattingForCertificate(
            falecido_dois.numero_certidao_obito,
          ),
          data_emissao_certidao_obito:
            falecido_dois.data_emissao_certidao_obito,
          uf_emissao_certidao_obito: falecido_dois.uf_emissao_certidao_obito,
          morte_por_covid: falecido_dois.morte_por_covid ? 'Sim' : 'Não',
          recebia_beneficio: falecido_dois.recebia_beneficio ? 'Sim' : 'Não',
          fonte_beneficio: falecido_dois.fonte_beneficio
            ? falecido_dois.fonte_beneficio
            : '',
          valor_beneficio: falecido_dois.valor_beneficio
            ? addFormattingPrice(+falecido_dois.valor_beneficio)
            : 0,
        })
      }
    },
    [setValue],
  )

  const responsibleCheck = useCallback(
    (instituicao_responsavel: Instituicao, tutor_responsavel: Tutor) => {
      if (instituicao_responsavel) {
        setValue('instituicao_responsavel', {
          id: instituicao_responsavel.id,
          nome: instituicao_responsavel.nome,
          modalidade: instituicao_responsavel.modalidade,
          natureza: instituicao_responsavel.natureza,
          municipio: instituicao_responsavel.municipio,
          cnpj: instituicao_responsavel.cnpj
            ? addFormattingForCNPJ(instituicao_responsavel.cnpj)
            : '',
          email: instituicao_responsavel.email,
          telefone: addFormattingForPhone(instituicao_responsavel.telefone),
          ativa: instituicao_responsavel.ativa,
          endereco: {
            municipio: instituicao_responsavel.endereco.municipio,
            logradouro: instituicao_responsavel.endereco.logradouro,
            numero: instituicao_responsavel.endereco.numero,
            bairro: instituicao_responsavel.endereco.bairro,
            complemento: instituicao_responsavel.endereco.complemento,
            ponto_de_referencia:
              instituicao_responsavel.endereco.ponto_de_referencia,
            uf: instituicao_responsavel.endereco.uf,
            cep: instituicao_responsavel.endereco.cep,
          },
        })
      } else if (tutor_responsavel) {
        setValue('tutor_responsavel', {
          id: tutor_responsavel.id,
          nome: tutor_responsavel.nome,
          data_nascimento: tutor_responsavel.data_nascimento,
          cpf: addFormattingForCPF(tutor_responsavel.cpf),
          rg: addFormattingForRG(tutor_responsavel.rg),
          orgao_expedidor: tutor_responsavel.orgao_expedidor,
          uf_orgao_expedidor: tutor_responsavel.uf_orgao_expedidor,
          data_expedicao: tutor_responsavel.data_expedicao,
          email: tutor_responsavel.email,
          telefone: addFormattingForPhone(tutor_responsavel.telefone),
          telefone_alternativo: addFormattingForPhone(
            tutor_responsavel.telefone_alternativo,
          ),
          endereco: {
            municipio: tutor_responsavel.endereco.municipio,
            logradouro: tutor_responsavel.endereco.logradouro,
            numero: tutor_responsavel.endereco.numero,
            bairro: tutor_responsavel.endereco.bairro,
            complemento: tutor_responsavel.endereco.complemento,
            ponto_de_referencia: tutor_responsavel.endereco.ponto_de_referencia,
            uf: tutor_responsavel.endereco.uf,
            cep: tutor_responsavel.endereco.cep,
          },
        })
      }
    },
    [setValue],
  )

  useEffect(() => {
    if (data) {
      const {
        dataRequest: {
          tipo_orfandade,
          orfao,
          instituicao_responsavel,
          tutor_responsavel,
          falecido_um,
          falecido_dois,
        },
      } = data
      setValue('tipo_orfandade', tipo_orfandade)
      setValue('orfao', {
        id: orfao.id,
        nome: orfao.nome,
        cpf: addFormattingForCPF(orfao.cpf),
        nis: addFormattingForNIS(orfao.nis),
        sexo: orfao.sexo,
        data_nascimento: orfao.data_nascimento,
        recebe_beneficio: orfao.recebe_beneficio ? 'Sim' : 'Não',
        fonte_beneficio: orfao.fonte_beneficio ? orfao.fonte_beneficio : '',
        valor_beneficio: orfao.valor_beneficio
          ? addFormattingPrice(+orfao.valor_beneficio)
          : 0,
      })

      setValue('falecido_um', {
        id: falecido_um.id,
        nome: falecido_um.nome,
        cpf: addFormattingForCPF(falecido_um.cpf),
        numero_certidao_obito: addFormattingForCertificate(
          falecido_um.numero_certidao_obito,
        ),
        data_emissao_certidao_obito: falecido_um.data_emissao_certidao_obito,
        uf_emissao_certidao_obito: falecido_um.uf_emissao_certidao_obito,
        morte_por_covid: falecido_um.morte_por_covid ? 'Sim' : 'Não',
        recebia_beneficio: falecido_um.recebia_beneficio ? 'Sim' : 'Não',
        fonte_beneficio: falecido_um.fonte_beneficio
          ? falecido_um.fonte_beneficio
          : '',
        valor_beneficio: falecido_um.valor_beneficio
          ? addFormattingPrice(+falecido_um.valor_beneficio)
          : 0,
      })

      deceasedTwoCheck(falecido_dois)
      responsibleCheck(instituicao_responsavel, tutor_responsavel)
    }
  }, [data, setValue, deceasedTwoCheck, responsibleCheck])

  const deceasedFields = (falecido: 'falecido_um' | 'falecido_dois') => {
    return (
      <>
        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            _required={false}
            label="Nome completo"
            type={'text'}
            {...register(`${falecido}.nome`)}
            isReadOnly
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            _required={false}
            label="CPF"
            type={'text'}
            {...register(`${falecido}.cpf`)}
            isReadOnly
          />
          <Input
            _required={false}
            label="Número de certidão de óbito"
            type={'text'}
            {...register(`${falecido}.numero_certidao_obito`)}
            isReadOnly
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            _required={false}
            label="UF emissão de certidão óbito"
            type={'text'}
            {...register(`${falecido}.uf_emissao_certidao_obito`)}
            isReadOnly
          />
          <Input
            _required={false}
            label="Data da emissão da certidão de óbito"
            type={'date'}
            {...register(`${falecido}.data_emissao_certidao_obito`)}
            isReadOnly
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            _required={false}
            label="Morte por covid"
            type={'text'}
            {...register(`${falecido}.morte_por_covid`)}
            isReadOnly
          />
          <Input
            _required={false}
            label="Recebia benefício"
            type={'text'}
            {...register(`${falecido}.recebia_beneficio`)}
            isReadOnly
          />
        </SimpleGrid>
      </>
    )
  }

  const addressFields = (
    responsavel: 'instituicao_responsavel' | 'tutor_responsavel',
  ) => {
    return (
      <>
        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            _required={false}
            label="Município"
            type={'text'}
            {...register(`${responsavel}.endereco.municipio`)}
            isReadOnly
          />
          <Input
            _required={false}
            label="Bairro"
            type={'text'}
            {...register(`${responsavel}.endereco.bairro`)}
            isReadOnly
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            _required={false}
            label="Logradouro"
            type={'text'}
            {...register(`${responsavel}.endereco.logradouro`)}
            isReadOnly
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            _required={false}
            label="Cep"
            type={'text'}
            mask={'*****-***'}
            {...register(`${responsavel}.endereco.cep`)}
            isReadOnly
          />
          <Input
            _required={false}
            label="Número"
            type={'text'}
            {...register(`${responsavel}.endereco.numero`)}
            isReadOnly
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            _required={false}
            label="Complemento"
            type={'text'}
            {...register(`${responsavel}.endereco.complemento`)}
            isReadOnly
          />
          <Input
            _required={false}
            label="UF"
            type={'text'}
            {...register(`${responsavel}.endereco.uf`)}
            isReadOnly
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            _required={false}
            label="Ponto de referência"
            type={'text'}
            {...register(`${responsavel}.endereco.ponto_de_referencia`)}
            isReadOnly
          />
        </SimpleGrid>
      </>
    )
  }

  return (
    <VStack spacing={'8'}>
      <Heading size={'md'} fontWeight={'normal'}>
        Informações do órfão
      </Heading>

      <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
        <Input
          _required={false}
          label="Nome completo"
          type={'text'}
          {...register('orfao.nome')}
          isReadOnly
        />
      </SimpleGrid>

      <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
        <Input
          _required={false}
          label="CPF"
          type={'text'}
          {...register('orfao.cpf')}
          isReadOnly
        />

        <Input
          _required={false}
          label="Sexo"
          type={'text'}
          {...register('orfao.sexo')}
          isReadOnly
        />
      </SimpleGrid>

      <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
        <Input
          _required={false}
          label="NIS"
          type={'text'}
          {...register('orfao.nis')}
          isReadOnly
        />

        <Input
          _required={false}
          label="Data de nascimento"
          type={'date'}
          {...register('orfao.data_nascimento')}
          isReadOnly
        />
      </SimpleGrid>

      <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
        <Input
          _required={false}
          label="Tipo orfandade"
          type={'text'}
          {...register('tipo_orfandade')}
          isReadOnly
        />

        <Input
          _required={false}
          label="Recebe benefício"
          type={'text'}
          {...register('orfao.recebe_beneficio')}
          isReadOnly
        />
      </SimpleGrid>

      {orfao_beneficio === 'Sim' && (
        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            _required={false}
            label="Fonte benefício"
            type={'text'}
            {...register('orfao.fonte_beneficio')}
            isReadOnly
          />

          <Input
            _required={false}
            label="Valor benefício"
            type={'text'}
            {...register('orfao.valor_beneficio')}
            isReadOnly
          />
        </SimpleGrid>
      )}

      <Divider my={'2'} borderColor={'gray.700'} />

      <Heading size={'md'} fontWeight={'normal'}>
        Informações do falecido (1)
      </Heading>

      {deceasedFields('falecido_um')}

      {falecido_um_beneficio === 'Sim' && (
        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            _required={false}
            label="Fonte benefício"
            type={'text'}
            {...register('falecido_um.fonte_beneficio')}
            isReadOnly
          />

          <Input
            _required={false}
            label="Valor benefício"
            type={'text'}
            {...register('falecido_um.valor_beneficio')}
            isReadOnly
          />
        </SimpleGrid>
      )}

      {tipo_da_orfandade === 'BILATERAL' && (
        <>
          <Heading size={'md'} fontWeight={'normal'}>
            Informações do falecido (2)
          </Heading>

          {deceasedFields('falecido_dois')}

          {falecido_dois_beneficio === 'Sim' && (
            <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
              <Input
                _required={false}
                label="Fonte benefício"
                type={'text'}
                {...register('falecido_dois.fonte_beneficio')}
                isReadOnly
              />

              <Input
                _required={false}
                label="Valor benefício"
                type={'text'}
                {...register('falecido_dois.valor_beneficio')}
                isReadOnly
              />
            </SimpleGrid>
          )}
        </>
      )}
      {has_tutor && (
        <>
          <Divider my={'2'} borderColor={'gray.700'} />

          <Heading size={'md'} fontWeight={'normal'}>
            Tutor responsável
          </Heading>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              _required={false}
              label="Nome completo"
              type={'text'}
              {...register('tutor_responsavel.nome')}
              isReadOnly
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              _required={false}
              label="CPF"
              type={'text'}
              {...register('tutor_responsavel.cpf')}
              isReadOnly
            />

            <Input
              _required={false}
              label="RG"
              type={'text'}
              {...register('tutor_responsavel.rg')}
              isReadOnly
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              _required={false}
              label="Data de expedição do RG"
              type={'date'}
              {...register('tutor_responsavel.data_expedicao')}
              isReadOnly
            />

            <Input
              _required={false}
              label="Órfão expedidor do RG"
              type={'text'}
              {...register('tutor_responsavel.orgao_expedidor')}
              isReadOnly
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              _required={false}
              label="UF órgão expedidor"
              type={'text'}
              {...register('tutor_responsavel.uf_orgao_expedidor')}
              isReadOnly
            />

            <Input
              _required={false}
              label="Data de nascimento"
              type={'date'}
              {...register('tutor_responsavel.data_nascimento')}
              isReadOnly
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              _required={false}
              label="Telefone"
              type={'text'}
              {...register('tutor_responsavel.telefone')}
              isReadOnly
            />

            <Input
              _required={false}
              label="Telefone alternativo"
              type={'text'}
              {...register('tutor_responsavel.telefone_alternativo')}
              isReadOnly
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              _required={false}
              label="Email"
              type={'text'}
              {...register('tutor_responsavel.email')}
              isReadOnly
            />
          </SimpleGrid>

          <Heading size={'md'} fontWeight={'normal'}>
            Endereço tutor
          </Heading>

          {addressFields('tutor_responsavel')}
        </>
      )}
      {has_institution && (
        <>
          <Divider my={'2'} borderColor={'gray.700'} />

          <Heading size={'md'} fontWeight={'normal'}>
            Instituição responsável
          </Heading>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              _required={false}
              label="Nome da instituição"
              type={'text'}
              {...register('instituicao_responsavel.nome')}
              isReadOnly
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              _required={false}
              label="Email"
              type={'text'}
              {...register('instituicao_responsavel.email')}
              isReadOnly
            />

            <Input
              _required={false}
              label="Modalidade"
              type={'text'}
              {...register('instituicao_responsavel.modalidade')}
              isReadOnly
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              _required={false}
              label="Natureza"
              type={'text'}
              {...register('instituicao_responsavel.natureza')}
              isReadOnly
            />

            <Input
              _required={false}
              label="Telefone"
              type={'text'}
              {...register('instituicao_responsavel.telefone')}
              isReadOnly
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              _required={false}
              label="CNPJ"
              type={'text'}
              {...register('instituicao_responsavel.cnpj')}
              isReadOnly
            />
          </SimpleGrid>

          <Heading size={'md'} fontWeight={'normal'}>
            Endereço instituição
          </Heading>

          {addressFields('instituicao_responsavel')}
        </>
      )}
    </VStack>
  )
}
