import { ElementType, ReactNode } from 'react'
import {
  Icon,
  Text,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps
} from '@chakra-ui/react'
import { ActiveLink } from './ActiveLink'

interface NavLinkProps extends ChakraLinkProps {
  icon: ElementType
  children: ReactNode
  href: string
}

export function NavLink({ icon, children, href, ...rest }: NavLinkProps) {
  return (
    <ActiveLink to={href}>
      <ChakraLink
        as={'a'}
        display={'flex'}
        alignItems={'center'}
        color={'blue_light.600'}
        {...rest}
      >
        <Icon as={icon} fontSize={'20'} />
        <Text ml={'4'} fontWeight={'medium'}>
          {children}
        </Text>
      </ChakraLink>
    </ActiveLink>
  )
}
