import { useQuery } from 'react-query'
import { api } from '../../../services/axios'
import { IRequest } from '../../../interfaces/RequestInterface'

interface GetRequestsResponse {
  count: number
  results: IRequest[]
}

export const getLinkedRequests = async (
  page: number,
  perPage: number,
  agentId?: number
): Promise<GetRequestsResponse> => {
  const {
    data: { count, results }
  } = await api.get<GetRequestsResponse>(
    `agentes/${agentId}/solicitacoes?limit=${perPage}&offset=${
      (page - 1) * perPage
    }`
  )
  return { count, results }
}

export const useLinkedRequests = (
  page: number,
  perPage: number,
  agentId?: number
) => {
  return useQuery(
    ['linked', page, perPage, agentId],
    () => getLinkedRequests(page, perPage, agentId),
    {
      staleTime: 1000 * 60 * 10 // 10 minutes
    }
  )
}
