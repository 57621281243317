import { Box, Button, Stack, Flex } from "@chakra-ui/react"
import { TextItem } from "./TextItem"
import { Link } from "react-router-dom"
import { addFormattingForCPF } from "../../../../utils"

interface Municipio {
    nome: string
    cod_ibge: number
}

interface AgenteCras {
    id: number
    nome: string
    cpf: string
    profissao: string
    municipios: Municipio[]
}


export function CardAgentCras({id, nome, cpf, profissao, municipios }: AgenteCras) {
    return (
        <Flex rounded={'8'}
            bgColor={'platinum.100'}
            overflow={'hidden'}
            boxShadow={'2px 2px 4px rgba(0, 0, 0, 0.25)'}
            flexDirection={"column"}
            justifyContent={'space-between'}
            p={4}
        >
            <Box>
                <Stack
                    align={'flex-start'}
                    pb={2}
                    minHeight={'160px'}
                >
                    <TextItem color="blue.500">{nome}</TextItem>
                    <TextItem label="CPF"> {addFormattingForCPF(cpf)}</TextItem>
                    <TextItem label="Profissão"> {profissao}</TextItem>
                    <TextItem label="Município(s) de atuação">{municipios.map((municipio, index) => (
                        <>
                            {index === municipios.length - 1 ? (municipio + ".") : (municipio + ", ")}
                        </>
                    ))}</TextItem>
                </Stack>
            </Box>
            <Box borderTop={'1px solid rgba(0, 0, 0, 0.25)'} width={'100%'} mt={4} pt={2}>
                <Link to={`/agent-cras/${id}/detail`}>
                    <Button as={'a'}
                        size={'sm'}
                        fontSize={'sm'}
                        color={'platinum.50'}
                        bg={'blue.200'}
                        _hover={{ bg: 'blue.300' }}
                        p={'4'}
                        bottom={'0'}
                        position={'relative'}
                        >
                        Detalhar
                    </Button>
                </Link>
            </Box>

        </Flex>
    )
}