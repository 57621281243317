import { Image } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import LogoRNAcolhe from '../../assets/logo.png'


export function Logo() {
  return (
    <Link to="/requests">
      <Image h="3rem" objectFit="cover" src={LogoRNAcolhe} alt="RN-ACOLHE" />
    </Link>
  )
}
