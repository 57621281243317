import { Stack } from '@chakra-ui/react'
import {
  RiCommunityLine, RiGroupLine,
  RiFileListLine,RiFileTransferLine
} from 'react-icons/ri'
import { NavLink } from './NavLink'
import { NavSection } from './NavSection'

export function SidebarNav() {
  return (
    <Stack spacing={'12'} align={'flex-start'}>
      <NavSection title={'SOLICITAÇÕES'}>
        <NavLink href="/requests" icon={RiFileListLine}>
          Solicitações cadastradas
        </NavLink>
        <NavLink href="/linked" icon={RiFileTransferLine}>
          Solicitações vinculadas
        </NavLink>
      </NavSection>

      <NavSection title={'CADASTROS'}>
        <NavLink href="/institutions" icon={RiCommunityLine}>
          Instituições
        </NavLink>
        <NavLink href="/agent-cras" icon={RiGroupLine}>
          Agentes CRAS
        </NavLink>
      </NavSection>
    </Stack>
  )
}
