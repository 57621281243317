import {
  Button,
  Flex,
  Icon,
  IconButton,
  useBreakpointValue
} from '@chakra-ui/react'
import { RiMenuLine } from 'react-icons/ri'
import { IoExitOutline } from 'react-icons/io5'
import { useSidebarDrawer } from '../../contexts/SidebarDrawerContext'
import { Logo } from './Logo'
import { Profile } from './Profile'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

export function Header() {
  const { signOut } = useAuth()
  const { onOpen } = useSidebarDrawer()
  const navigateTo = useNavigate()

  function handleLogout() {
    signOut()
    navigateTo('/')
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  return (
    <Flex
      as={'header'}
      w={'100%'}
      maxWidth={1480}
      h={'20'}
      mx={'auto'}
      // mt={'4'}
      px={'6'}
      align={'center'}
      justify={'space-between'}
    >
      {!isWideVersion && (
        <IconButton
          aria-label={'Abrir menu'}
          color={'platinum.50'}
          icon={<Icon as={RiMenuLine} />}
          fontSize={'24'}
          variant={'unstated'}
          onClick={onOpen}
          mr={'2'}
        ></IconButton>
      )}

      <Logo />

      <Flex align={'center'} ml={'auto'}>
        <Profile showProfileData={isWideVersion} />
        <Button
          variant={'solid'}
          color={'platinum.50'}
          size={isWideVersion ? "md" : "sm"}
          minWidth="fit-content"
          bgColor={'blue.200'}
          _hover={{ bgColor: 'blue.400' }}
          leftIcon={<Icon as={IoExitOutline} fontSize={'20'} />}
          onClick={handleLogout}
          title="Sair"
          ml={2}
        >
          Sair
        </Button>
      </Flex>
    </Flex>
  )
}
