
const labels: string[] = [
    'Certidão de nascimento ou documento oficial de adoção',
    'Certidões de óbitos dos falecidos',
    'Folha resumo cadastro único',
    'Comprovante de inscrição da Criança/Adolescente no Cadastro de Pessoa Física (CPF)',
    'Certidão emitida pela instituição que gere o regime de previdência do(s) falecido(s)',
    'Comprovantes de renda familiar (anterior ao óbito)',
    'Termo de responsabilidade legal emitido pelo Conselho Tutelar/Guarda expedida por Autoridade Judiciária',
    'Documentos de identificação do Responsável Legal (RG, CPF, Comprovante de Residência)',
    'Termo de responsabilidade de comunicação de ocorrências preenchido',
    'Comprovação do domicílio da Criança/Adolescente'
]


export const useLabels = () => labels