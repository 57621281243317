import { forwardRef, ForwardRefRenderFunction } from 'react'
import { FieldError } from 'react-hook-form'
import {
  Text,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react'
import { InfoIcon, WarningTwoIcon } from '@chakra-ui/icons'
import InputMask from 'react-input-mask'

interface InputProps extends ChakraInputProps {
  name: string
  _required?: boolean
  label?: string
  mask?: string
  format?: any
  isAllowed?: any
  info?: string
  error?: FieldError
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    _required = true,
    label,
    mask,
    format,
    isAllowed,
    info,
    error = null,
    ...rest
  },
  ref,
) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel display={'flex'} htmlFor={label}>
          {label}
          {_required && (
            <Text marginLeft={'0.2rem'} color="red.500">
              *
            </Text>
          )}
        </FormLabel>
      )}
      <ChakraInput
        as={!!mask ? InputMask : 'input'}
        id={name}
        name={name}
        mask={mask}
        format={format}
        isAllowed={isAllowed}
        focusBorderColor={'blue.300'}
        borderColor={'black.300'}
        bgColor={'platinum.200'}
        variant={'filled'}
        _hover={{ borderColor: 'blue.300' }}
        size={'md'}
        ref={ref}
        {...rest}
      />
      {info && !error && (
        <FormHelperText
          borderRadius={'md'}
          bg={'platinum.300'}
          boxShadow={'2px 2px 4px rgba(0, 0, 0, 0.25)'}
          p={1}
          color={'black.900'}
          fontSize={'md'}
        >
          <InfoIcon mt={-1} mr={1} color={'blue.100'} />
          {info}
        </FormHelperText>
      )}
      {!!error && (
        <FormErrorMessage p={1} fontSize={'md'}>
          <WarningTwoIcon mt={-1} mr={1} color={'red.500'} />
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export const Input = forwardRef(InputBase)
