import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom'

import { refreshTokenIsValid } from '../contexts/AuthContext'
import { Layout } from '../pages/Layout'
import { SignIn } from '../pages'
import { RequestList } from '../pages/RequestList'
import { LinkedRequestsList } from '../pages/LinkedRequestsList'
import EvaluateRequest from '../pages/EvaluateRequest'
import { InstitutionList } from '../pages/institutions/index'
import { CreateInstitution } from '../pages/institutions/Create'
import { RedefinePassword } from '../pages/RedefinePassword'
import { AgentCrasList } from '../pages/AgentCrasList'
import { CreateAgentCras } from '../pages/AgentCrasList/Create'
import { AgentCrasDetail } from '../pages/AgentCrasDetail'

interface PrivateRouteProps {
  children: React.ReactElement
  redirectTo: string
}

function PrivateRoute({ children, redirectTo }: PrivateRouteProps) {
  return refreshTokenIsValid() ? children : <Navigate to={redirectTo} />
}

export function Routes() {
  return (
    <ReactRoutes>
      <Route index element={<SignIn />} />
      <Route path="redefinepassword" element={<RedefinePassword />} />
      <Route
        path="requests"
        element={
          <PrivateRoute redirectTo="/">
            <Layout />
          </PrivateRoute>
        }
      >
        <Route index element={<RequestList />} />
        <Route path="*" element={<RequestList />} />
      </Route>

      <Route
        path="linked"
        element={
          <PrivateRoute redirectTo="/">
            <Layout />
          </PrivateRoute>
        }
      >
        <Route index element={<LinkedRequestsList />} />
        <Route path=":requestId" element={<EvaluateRequest />} />
        <Route path="*" element={<LinkedRequestsList />} />
      </Route>

      <Route
        path="institutions"
        element={
          <PrivateRoute redirectTo="/">
            <Layout />
          </PrivateRoute>
        }
      >
        <Route index element={<InstitutionList />} />
        <Route path="create/" element={<CreateInstitution />} />
        <Route path="*" element={<InstitutionList />} />
      </Route>

      <Route
        path="agent-cras"
        element={
          <PrivateRoute redirectTo="/">
            <Layout />
          </PrivateRoute>
        }
      >
        <Route index element={<AgentCrasList />} />
        <Route path="create/" element={<CreateAgentCras />} />
        <Route path=':agenteCrasId/detail' element={<AgentCrasDetail />}/>
        <Route path="*" element={<AgentCrasList />} />
      </Route>



      <Route path="*" element={<SignIn />} />
    </ReactRoutes>
  )
}
