import {
  Flex,
  Box,
  Button,
  Icon,
  Heading,
  Link as ChakraLink,
  Divider,
  Tag,} from '@chakra-ui/react'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { JustifyModal } from '../../Modals/JustifyInvalidateModal'
import { useState } from 'react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

interface EvaluateDocumentProps {
  id: number
  label: string
  fileLink: string
  valid: boolean | null
  validateDocument: (documentId: number) => void
  invalidateDocument: (documentId: number, justify: string) => void
}

export function EvaluateDocument({
  id,
  label,
  fileLink,
  valid,
  validateDocument,
  invalidateDocument,
}: EvaluateDocumentProps) {
  const [isOpenJustity, setIsOpenJustify] = useState<boolean>(false)

  const statusTag = () => {
    return valid === null ? (
      <Tag w="100%">Documento não avaliado</Tag>
    ) : valid ? (
      <Tag w="100%" bg={'green.100'} color={'white'}>
        Documento validado
      </Tag>
    ) : (
      <Tag w="100%" bg={'red.400'} color={'white'}>
        Documento invalidado
      </Tag>
    )
  }

  const toggleJustifyModal = () => {
    setIsOpenJustify(!isOpenJustity)
  }

  const handleConfirmInvalidate = (dataJustify: string) => {
    invalidateDocument(id, dataJustify)
    toggleJustifyModal()
  }

  return (
    <Box mt="4">
      <JustifyModal
        isOpen={isOpenJustity}
        close={() => setIsOpenJustify(false)}
        confirm={(justificativa: string) =>
          handleConfirmInvalidate(justificativa)
        }
      />

      <Heading as="h3" mb="1" fontSize="md" fontWeight="bold">
        {label}
      </Heading>

      {statusTag()}

      <Flex
        flexDirection={['column', 'column', 'row']}
        justifyContent="space-between"
        gap="4"
        py="2"
      >
        <ChakraLink
          href={fileLink}
          isExternal
          display={'flex'}
          alignItems={'center'}
          justifyContent="center"
          minWidth="fit-content"
          rounded="md"
          bgColor={'yellow.700'}
          color={'platinum.50'}
          px="4"
          py="2"
        >
          <Icon as={ExternalLinkIcon} mr={2} />
          Visualizar documento
        </ChakraLink>

        <Flex
          flexDirection={['column', 'row', 'row']}
          justifyContent={['center', 'flex-end', 'flex-end']}
          gap="4"
        >
        <Button
          colorScheme="red"
          leftIcon={<AiOutlineClose />}
          size={'md'}
          minWidth="fit-content"
          onClick={toggleJustifyModal}
        >
          Invalidar
        </Button>

        <Button
          color={'platinum.50'}
          bgColor={'green.50'}
          _hover={{ bgColor: 'green.100' }}
          leftIcon={<AiOutlineCheck />}
          size={'md'}
          minWidth="fit-content"
          onClick={() => validateDocument(id)}
        >
          Validar
        </Button>
        </Flex>
      </Flex>

      <Divider my={'6'} borderColor={'gray.700'} />
    </Box>
  )
}
