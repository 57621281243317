import { api } from "../../../services/axios"
import { useQuery } from 'react-query'

interface Institutions{
    id: number
    nome: string
    municipio: string
}

export interface Iresponse{
    results: Institutions[]
    count: number
}
const getInstitutions = async (page: number, registerPerPage: number): Promise<Iresponse> =>{
    const {data:{results, count}} = await api.get<Iresponse>(`instituicoes/?limit=${registerPerPage}&offset=${(page - 1) * registerPerPage}`)
    return {results, count}
}

export const useInstitutions = (page: number, registerPerPage: number) =>{
    return useQuery(['instituicoes', page], () => getInstitutions(page, registerPerPage),{
        staleTime: 1000 * 60 * 1,
    })  
}
