import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useBreakpointValue,
  Flex,
  SimpleGrid
} from '@chakra-ui/react'

interface AlertProps {
  status: 'success' | 'error' | 'warning' | 'info'
  title: string
  description: string
}

export function AlertComponent({
  status = 'info',
  title,
  description
}: AlertProps) {
  const isWideVersion = useBreakpointValue({
    base: false,
    md: true
  })

  return (
    <SimpleGrid flex={'1'} gap={'4'} minChildWidth={'320px'}>
      <Flex justify={'center'} align={'center'}>
        <Alert
          status={status}
          variant={'solid'}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
          borderRadius={isWideVersion ? '8' : '0'}
        >
          <AlertIcon boxSize={'2.5rem'} mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            {title}
          </AlertTitle>
          <AlertDescription maxWidth="sm">{description}</AlertDescription>
        </Alert>
      </Flex>
    </SimpleGrid>
  )
}
