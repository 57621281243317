import {
  Flex,
  Heading,
  Button,
  Icon,
  Spinner,
  SimpleGrid,
} from '@chakra-ui/react'
import { RiAddLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { Pagination } from '../../components/Pagination'
import { Content } from '../../components/Content'
import { BreadCrumb } from '../../components/BreadCrumb'
import { CardInstitutions } from '../../components/CardInstitutions'
import { AlertComponent } from '../../components/Alert/index'
import { useInstitutions } from './hook/useInstitutions'

export function InstitutionList() {
  const [page, setPage] = useState(1)
  const registersPerPage = 9

  const { data, isLoading, error, isFetching } = useInstitutions(
    page,
    registersPerPage,
  )

  function showPagination(count: number) {
    return count > 9 && registersPerPage === 9
  }

  return (
    <>
      <Content>
        <title>RN-ACOLHE - Instituições</title>

        <BreadCrumb currentPath="Instituições" />

        <Flex mb={'8'} justify={'space-between'} align={'center'}>
          <Heading size={'lg'} fontWeight={'normal'}>
            Instituições
            {!isLoading && isFetching && (
              <Spinner size={'sm'} color={'blue.300'} ml={'4'} />
            )}
          </Heading>

          <Link to={'/institutions/create'}>
            <Button
              as={'a'}
              size={'sm'}
              fontSize={'sm'}
              color={'platinum.50'}
              bgColor={'green.50'}
              _hover={{ bgColor: 'green.100' }}
              leftIcon={<Icon as={RiAddLine} fontSize={'20'} />}
            >
              Cadastrar Instituição
            </Button>
          </Link>
        </Flex>

        {isLoading ? (
          <Flex color={'blue.300'} justify={'center'} align={'center'}>
            <Spinner />
          </Flex>
        ) : error ? (
          <AlertComponent
            status={'error'}
            title={'Ocorreu um erro'}
            description={'Não foi possível listar as instituições'}
          />
        ) : data?.results.length === 0 ? (
          <AlertComponent
            status={'warning'}
            title={'Instituições não encontradas'}
            description={
              'Não foi possível encontrar instituições vinculadas ao profissional'
            }
          />
        ) : (
          <>
            <SimpleGrid flex={'1'} gap={'4'} minChildWidth={'320px'}>
              {data?.results.map(institution => (
                <CardInstitutions
                  key={institution.id}
                  institution={institution}
                  id={institution.id}
                />
              ))}
            </SimpleGrid>

            {data?.results.length !== undefined &&
              data?.results.length !== 0 && (
                <>
                  {showPagination(data?.count) && (
                    <Pagination
                      totalCountOfRegisters={data?.count}
                      titlePage={'instituições'}
                      currentPage={page}
                      onPageChange={setPage}
                      registersPerPage={registersPerPage}
                    />
                  )}
                </>
              )}
          </>
        )}
      </Content>
    </>
  )
}
