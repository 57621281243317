import { useState } from 'react'
import { Flex, Heading, Spinner, SimpleGrid } from '@chakra-ui/react'

import { AlertComponent } from '../../components/Alert'
import { BreadCrumb } from '../../components/BreadCrumb'
import { Content } from '../../components/Content'
import { useLinkedRequests } from './hook'
import { Pagination } from '../../components/Pagination'
import { CardLinkedRequest } from '../../components/Cards/LinkedRequest'
import { useAuth } from '../../contexts/AuthContext'
import { Subtitles } from '../../components/Subtitles'

export function LinkedRequestsList() {
  const { user } = useAuth()
  const [page, setPage] = useState(1)
  const perPage = 9

  const { isLoading, isFetching, data, error } = useLinkedRequests(
    page,
    perPage,
    user && user.agente_id,
  )

  function showPagination(count: number) {
    return count > 9 && perPage === 9
  }

  return (
    <Content>
      <title>RN-ACOLHE - Solicitações vinculadas</title>
      <BreadCrumb currentPath="Solicitações vinculadas" />
      <Flex mb={'8'} justify={'space-between'} align={'center'}>
        <Heading size={'lg'} fontWeight={'normal'}>
          Solicitações vinculadas
          {!isLoading && isFetching && (
            <Spinner size={'sm'} color={'blue.300'} ml={'4'} />
          )}
        </Heading>
      </Flex>
      {isLoading ? (
        <Flex color={'blue.300'} justify={'center'} align={'center'}>
          <Spinner />
        </Flex>
      ) : error ? (
        <AlertComponent
          status={'error'}
          title={'Ocorreu um erro'}
          description={`Não foi possível listar as solicitações vinculadas.`}
        />
      ) : !data?.results.length ? (
        <AlertComponent
          status={'warning'}
          title={'Nenhuma solicitação vinculada encontrada'}
          description={`Não encontramos nenhuma solicitação vinculada.`}
        />
      ) : (
        <>
          <Subtitles subtitlesIdFilter={[3, 4, 5, 6, 7]} />
          <SimpleGrid flex={'1'} gap={'4'} minChildWidth={'320px'}>
            {data?.results.map(request => (
              <>
                <CardLinkedRequest
                  key={request.id}
                  request={request}
                  hasJustificationModal={true}
                />
              </>
            ))}
          </SimpleGrid>

          {data?.results.length !== undefined && data?.results.length !== 0 && (
            <>
              {showPagination(data.count) && (
                <Pagination
                  totalCountOfRegisters={data.count}
                  registersPerPage={perPage}
                  currentPage={page}
                  onPageChange={setPage}
                  titlePage={'solicitações'}
                />
              )}
            </>
          )}
        </>
      )}
    </Content>
  )
}
