import { useQuery } from 'react-query'
import { api } from '../../../services/axios'
import { DetailRequest } from '../types'

const getDetailRequest = async (agentId?: number, requestId?: number): Promise<DetailRequest> => {
    const { data } = await api.get<DetailRequest>(`agentes/${agentId}/solicitacoes/${requestId}/`)
    return data
}

export const useDetailRequest = (agentId?: number, requestId?: number) => {
    return useQuery(['detailRequest', agentId, requestId], () => getDetailRequest(agentId, requestId))
}

