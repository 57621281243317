import { Badge, Box, Divider, Flex, Stack, Text, useBreakpointValue } from '@chakra-ui/react'
import { addFormattingForCPF } from '../../../utils'
import { useBgColorStatus } from '../../hooks/useBgColorStatus'

interface OrphanInfoCardProps {
  name: string
  cpf: string
  status: string
}

export function OrphanInfoCard({ name, cpf, status }: OrphanInfoCardProps) {
  const bgColorStatus = useBgColorStatus(status)

  const isWideVersion = useBreakpointValue({
    base: false,
    md: true
  })

  return (
    <Box
      rounded={'8'}
      bgColor={'platinum.100'}
      overflow={'hidden'}
      boxShadow={'2px 2px 4px rgba(0, 0, 0, 0.25)'}
      mb={'12'}
    >
      <Box p={3}>
        <Stack align={'flex-start'} mb={2}>
          <Text as="h2" fontWeight="bold" color={'blue.500'}>
            {name}
          </Text>
          <Text fontWeight="light">
            CPF: {addFormattingForCPF(cpf)}
          </Text>
        </Stack>

        <Divider my={'2'} borderColor={'gray.700'} />

        <Flex>
          <Badge
            rounded="full"
            variant="solid"
            bgColor={bgColorStatus}
            py="2"
            px="3"
            mb={isWideVersion ? '0' : '2'}
          >
            {status}
          </Badge>
        </Flex>
      </Box>
    </Box>
  )
}
