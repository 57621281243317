import { useEffect, useState } from "react"
import { api } from "../../../services/axios"


interface IEndereco {
    logradouro: string
    numero: string
    bairro: string
    complemento?: string
    ponto_de_referencia?: string
    uf: string
    cep: string
    municipio: number;
}

export interface IAgenteCras {
    endereco: IEndereco,
    nome: string;
    sexo: number;
    data_nascimento: string;
    cpf: string;
    rg: string;
    data_expedicao: string;
    orgao_expedidor: string;
    uf_orgao_expedidor: string;
    email: string;
    telefone: string;
    telefone_alternativo?: string;
    nome_profissao: string;
    numero_registro: string;
    municipios: string[];
}



export const useGetAgenteCras = (agenteId?: number, agenteCrasId?: number) => {
    const [agenteCras, setAgenteCras] = useState<IAgenteCras>()

    const getAgente = async () => {
        const response = await api.get<IAgenteCras>(`/agentes/${agenteId}/agentescras/${agenteCrasId}`)
        setAgenteCras(response.data)
    }

    useEffect(()=> {

        getAgente()
        
      },[])

    return agenteCras
}
