import  {extendTheme} from '@chakra-ui/react'

export const theme = extendTheme({
  colors: {
    gray: {
      "900": "#181b23",
      "800": "#1f2029",
      "700": "#353646",
      "600": "#4b4d63",
      "500": "#616580",
      "400": "#797d9a",
      "300": "#9699b0",
      "200": "#b3b5c6",
      "100": "#d1d2dc",
      "50": "#eeeef2",
    },
    black:{
      "900": "#0A0A0A",
      "800": "#141415",
      "700": "#1C1C1D",
      "600": "#28282A",
      "500": "#323234",
      "400": "#3C3C3E",
      "300": "#464649",
      "200": "#505053",
      "100": "#5A5A5E",
      "50": "#646468"
    },
    green:{
      "900": "#041009",
      "800": "#092013",
      "700": "#0D301C",
      "600": "#124026",
      "500": "#16502F",
      "400": "#1A6039",
      "300": "#1F7042",
      "200": "#227B49",
      "100": "#279055",
      "50": "#2CA05E"
    },
    green_light:{
      "900": "#359766",
      "800": "#3AA670",
      "700": "#40B57A",
      "600": "#4ABF85",
      "500": "#59C58F",
      "400": "#69CA99",
      "300": "#77CFA3",
      "200": "#86D5AD",
      "100": "#95DAB8",
      "50": "#A4DFC2"
    },
    blue:{
      "900": "#133953",
      "800": "#174563",
      "700": "#1B5074",
      "600": "#1F5C84",
      "500": "#236795",
      "400": "#2773A5",
      "300": "#2B7EB6",
      "200": "#2F8AC6",
      "100": "#3994D0",
      "50": "#4B9DD5"
    },
    blue_light:{
      "900": "#0DADE7",
      "800": "#18B7F2",
      "700": "#2BBDF3",
      "600": "#3EC3F4",
      "500": "#51C9F5",
      "400": "#67CFF6",
      "300": "#78D5F7",
      "200": "#8BD8F8",
      "100": "#9FE1F9",
      "50": "#B2E7FB",
    },
    yellow:{
      "900": "#DEA002",
      "800": "#F2AE02",
      "700": "#FDB90D",
      "600": "#FDBE21",
      "500": "#FDC435",
      "400": "#FDCB4A",
      "300": "#FDD05D",
      "200": "#FED672",
      "100": "#FEDC86",
      "50": "#FEE29A"
    },
    yellow__light:{
      "900": "#6A6211",
      "800": "#7C7313",
      "700": "#828316",
      "600": "#9F9419",
      "500": "#B0A41C",
      "400": "#C2B41E",
      "300": "#D4C521",
      "200": "#DECF2B",
      "100": "#E1D33D",
      "50": "#E2D545"
    },
    pink:{
      "900": "#E4112A",
      "800": "#EE1B34",
      "700": "#EF2E45",
      "600": "#F14156",
      "500": "#F25467",
      "400": "#F2576A",
      "300": "#F57A89",
      "200": "#F68D99",
      "100": "#F8A0AA",
      "50": "#F9B3BB"
    },
    platinum:{
      "900": "#A5A1A1",
      "800": "#AFACAC",
      "700": "#B9B6B6",
      "600": "#C3C1C1",
      "500": "#CDCBCB",
      "400": "#D7D5D5",
      "300": "#E1E0E0",
      "200": "#E7E6E6",
      "100": "#F5F5F5",
      "50": "#FFFFFF"
    }
  },
  fonts:{
    heading: '"Roboto", sans-serif',
    body: '"Roboto", sans-serif',
  },
  styles: {
    global: {
      body: {
        bg: 'blue.300',
        color: 'black.900',
      }
    }
  }
})