import { Button, Flex, Tooltip } from '@chakra-ui/react'

interface ActionMenuProps {
  evaluatedDocumentsCount: number
  baseDocumentsCount: number
  toggleDeferirModal: () => void
  toggleIndeferirModal: () => void
  toggleCorrigirModal: () => void
}

export function ActionMenu({
  evaluatedDocumentsCount,
  baseDocumentsCount,
  toggleDeferirModal,
  toggleIndeferirModal,
  toggleCorrigirModal,
}: ActionMenuProps) {
  const buttonsDisabled = evaluatedDocumentsCount < baseDocumentsCount

  return (
    <Flex
      flexDirection={['column', 'column', 'row']}
      justifyContent={['center', 'center', 'flex-end']}
    >
      <Tooltip
        label="Avalie os documentos contidos na solicitação para poder liberar as ações"
        placement="bottom"
        isDisabled={evaluatedDocumentsCount === baseDocumentsCount}
      >
        <Flex
          flexDirection={['column', 'column', 'row']}
          justifyContent={['center', 'flex-end', 'flex-end']}
          mt="4"
          gap="4"
          px="4"
        >
          <Button
            isDisabled={buttonsDisabled}
            size="md"
            minWidth="fit-content"
            colorScheme={'red'}
            type={'button'}
            onClick={toggleIndeferirModal}
          >
            Indeferir solicitação
          </Button>

          <Button
            isDisabled={buttonsDisabled}
            size="md"
            minWidth="fit-content"
            color={'platinum.50'}
            bgColor={'blue.100'}
            _hover={{ bgColor: 'blue.300' }}
            type={'button'}
            onClick={toggleCorrigirModal}
          >
            Solicitar correção
          </Button>

          <Button
            isDisabled={buttonsDisabled}
            size="md"
            minWidth="fit-content"
            color={'platinum.50'}
            bgColor={'green.50'}
            _hover={{ bgColor: 'green.100' }}
            type={'button'}
            onClick={toggleDeferirModal}
          >
            Deferir solicitação
          </Button>
        </Flex>
      </Tooltip>
    </Flex>
  )
}
