import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useNavigate, Link } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import { Input } from '../../components/Form/Input'
import { api } from '../../services/axios'
import {
  CreateInstitutionFormData,
  Errors,
  InstitutionData,
} from './../types/index'
import { CreateInstitutionFormSchema } from '../schemas/createInstitutionForm'
import { BreadCrumb } from '../../components/BreadCrumb'
import { formatInstitutionCreateData } from '../../utils'
import { queryClient } from '../../services/queryClient'
import { getCities } from '../../services/hooks/useCities'


export function CreateInstitution() {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<CreateInstitutionFormData>({
    resolver: yupResolver(CreateInstitutionFormSchema),
  })

  const CreateInstitution = useMutation(
    async (institutionData: InstitutionData) => {
      const { data } = await api.post('instituicoes/', institutionData)
      toast.success(`${data.mensagem}`)
    },
    { onSuccess, onError },
  )

  function onSuccess() {
    queryClient.invalidateQueries('institutions')
    navigate('/institutions')
  }

  function onError(error: any) {
    const data = error?.response.data
    const requestErrors = Object.entries(data).map(([key, value]) => ({
      type: 'manual',
      name: key,
      message: `${value}`,
    }))
    if (requestErrors.length) {
      requestErrors.forEach(({ type, name, message }: Errors) => {
        setError(name, { type, message })
      })
    }
    toast.error(
      'Erro ao tentar cadastrar a Instituição. Verifique os dados informados e tente novamente!',
    )
  }

  const handleCreateInstitutions: SubmitHandler<CreateInstitutionFormData> = async (
    values,
    event,
  ) => {
    event?.preventDefault()
    await new Promise(resolve => setTimeout(resolve, 2000))
    const institutionData = formatInstitutionCreateData(values)

    await CreateInstitution.mutateAsync(institutionData)
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  })

  return (
    <Box
      as={'form'}
      flex={'1'}
      borderRadius={isWideVersion ? '8' : '0'}
      bg={'platinum.100'}
      p={['6', '8']}
      onSubmit={handleSubmit(handleCreateInstitutions)}
    >
      <title>RN-ACOLHE - Cadastrar instituição</title>
      <BreadCrumb
        PreviousPaths={[{ name: 'Instituições', url: '/institutions' }]}
        currentPath="Cadastrar instituição"
      />
      <Heading size={'lg'} fontWeight={'normal'}>
        Cadastrar instituição
      </Heading>

      <Divider my={'6'} borderColor={'gray.700'} />

      <VStack spacing={'8'}>
        <Heading size={'md'} fontWeight={'normal'}>
          Dados da instituição
        </Heading>

        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            label={'Nome da Instituição'}
            type={'text'}
            {...register('nome')}
            error={errors.nome}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            label="CNPJ"
            type={'text'}
            _required={false}
            mask={'99.999.999/0009-99'}
            placeholder="99.999.999/0001-99"
            {...register('cnpj')}
            error={errors.cnpj}
          />

          <Input
            as={'select'}
            label="Natureza"
            type={'number'}
            {...register('natureza')}
            error={errors.natureza}
          >
            <option value="">--Selecione--</option>
            <option value="1">Governamental</option>
            <option value="2">Não governamental</option>
          </Input>
        </SimpleGrid>

        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            as={'select'}
            label="Modalidade"
            type={'number'}
            {...register('modalidade')}
            error={errors.modalidade}
          >
            <option value="">--Selecione--</option>
            <option value="1">Abrigo institucional</option>
            <option value="2">Casa lar</option>
            <option value="3">Família acolhedora</option>
          </Input>
        </SimpleGrid>

        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            label="Telefone/Celular"
            type={'text'}
            mask={'(99) 99999-9999'}
            placeholder="(00) 00000-0000"
            {...register('telefone')}
            error={errors.telefone}
          />

          <Input
            label="Email"
            type={'text'}
            {...register('email')}
            error={errors.email}
          />
        </SimpleGrid>

        <Divider my={'6'} borderColor={'gray.700'} />

        <Heading size={'md'} fontWeight={'normal'}>
          Dados de endereço
        </Heading>

        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            as={'select'}
            label={'Município'}
            type="text"
            {...register('municipio')}
            error={errors.municipio}
          >
            <option value="">--Selecione--</option>
            {getCities()
              .map(county => (
                <option key={county.id} value={county.id}>
                  {county.name}
                </option>
              ))
              .sort()}
          </Input>
          <Input
            label={'Bairro'}
            type="senha"
            {...register('bairro')}
            error={errors.bairro}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            label="Logradouro"
            type={'text'}
            {...register('logradouro')}
            error={errors.logradouro}
          />
          <Input
            label="Número"
            type={'text'}
            {...register('numero')}
            error={errors.numero}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            disabled
            label={'UF'}
            type="text"
            value={'RN'}
            {...register('uf')}
            error={errors.uf}
          />

          <Input
            label={'CEP'}
            type="text"
            mask={'*****-***'}
            placeholder="00000-000"
            {...register('cep')}
            error={errors.cep}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
          <Input
            label="Complemento"
            type={'text'}
            _required={false}
            {...register('complemento')}
            error={errors.complemento}
          />

          <Input
            label="Ponto de referência"
            type={'text'}
            _required={false}
            {...register('ponto_de_referencia')}
            error={errors.ponto_de_referencia}
          />
        </SimpleGrid>
      </VStack>
      <Flex mt={'8'} justify={'flex-end'}>
        <HStack spacing={'4'}>
          <Link to={'/institutions'}>
            <Button color={'platinum.50'} bgColor={'platinum.600'}>
              Cancelar
            </Button>
          </Link>
          <Button
            type={'submit'}
            bgColor={'blue.50'}
            _hover={{ bgColor: 'blue.200' }}
            color={'platinum.50'}
            isLoading={isSubmitting}
            loadingText={'Salvando'}
          >
            Salvar
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}
