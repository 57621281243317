import { useQuery } from 'react-query'
import { api } from '../../../services/axios'

type Request = {
  id: number
  nome: string
  municipio: string
  status: string
  created_at: string
}

interface GetRequestsResponse {
  count: number
  results: Request[]
}

export const getRequests = async (
  page: number,
  perPage: number
): Promise<GetRequestsResponse> => {
  const {
    data: { count, results }
  } = await api.get<GetRequestsResponse>(
    `solicitacoes?limit=${perPage}&offset=${(page - 1) * perPage}`
  )
  return { count, results }
}

export const useRequests = (page: number, perPage: number) => {
  return useQuery(
    ['requests', page, perPage],
    () => getRequests(page, perPage),
    {
      staleTime: 1000 * 60 * 10 // 10 minutes
    }
  )
}
