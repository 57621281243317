import {
  Button,
  Divider,
  Flex,
  Link as ChakraLink,
  Stack,
  Text,
  Image
} from '@chakra-ui/react'
import { useRef } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import { RedefinePasswordSchema, RequestCodeSchema } from './schemas'
import { Input } from '../../components/Form/Input'
import { useAuth } from '../../contexts/AuthContext'
import { api } from '../../services/axios'

import LogoRNAcolhe from '../../assets/logo.png'
import LogoSethas from '../../assets/logo_sethasrn.png'
import { RedefinePasswordFormData, RequestCodeFormData } from './interfaces'
import { Errors } from '../../interfaces/RequestInterface'

export function RedefinePassword() {
  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()
  const formRef = useRef<any>(null)
  const showFormCode = useRef(true)
  const showFormPassword = useRef(false)

  const {
    register: registerRequestCode,
    handleSubmit: handleSubmitRequestCode,
    formState: formStateRequestCode,
    watch: watchRequestCode,
    setError: setErrorRequestCode
  } = useForm<RequestCodeFormData>({
    resolver: yupResolver(RequestCodeSchema)
  })

  const handleRequestCode: SubmitHandler<RequestCodeFormData> = async (
    values,
    event
  ) => {
    event?.preventDefault()
    await new Promise(resolve => setTimeout(resolve, 2000))

    if (formRef.current) {
      try {
        const { status, data } = await api.post('senhas/redefinir/', values)
        if (status === 200) {
          showFormCode.current = false
          showFormPassword.current = true
        }
        toast.success(data.mensagem)
      } catch (errorRequestCode: any) {
        const data = errorRequestCode?.response.data

        const requestErrors = Object.entries(data).map(([key, value]) => ({
          type: 'manual',
          name: key,
          message: `${value}`
        }))

        if (requestErrors.length) {
          requestErrors.forEach(({ type, name, message }: Errors) => {
            setErrorRequestCode(name, { type, message })
          })
        }

        if (data.detail) {
          toast.error(`${data.detail}`)
        }

        if (data.mensagem) {
          toast.error(`${data.mensagem}`)
        }
      }
    }
  }

  const {
    register: registerRedefinePassword,
    handleSubmit: handleSubmitRedefinePassword,
    formState: formStateRedefinePassword,
    setValue: setValueRedefinePassword,
    setError: setErrorRedefinePassword
  } = useForm<RedefinePasswordFormData>({
    resolver: yupResolver(RedefinePasswordSchema)
  })

  setValueRedefinePassword('cpf', watchRequestCode('cpf'))

  const handleRedefinePassword: SubmitHandler<
    RedefinePasswordFormData
  > = async (values, event) => {
    event?.preventDefault()
    await new Promise(resolve => setTimeout(resolve, 2000))

    if (formRef.current) {
      try {
        delete values.confirmar_nova_senha

        const { status, data } = await api.post('senhas/atualizar/', values)
        if (status === 200) {
          showFormPassword.current = false
          toast.success(data.mensagem)
          navigate('/')
        }
      } catch (errorRedefinePassword: any) {
        const data = errorRedefinePassword?.response.data

        const requestErrors = Object.entries(data).map(([key, value]) => ({
          type: 'manual',
          name: key,
          message: `${value}`
        }))

        if (requestErrors.length) {
          requestErrors.forEach(({ type, name, message }: Errors) => {
            setErrorRedefinePassword(name, { type, message })
          })
        }

        if (data.detail) {
          toast.error(`${data.detail}`)
        }
      }
    }
  }

  if (isAuthenticated) {
    return <Navigate to="/orphans" replace={true} />
  }
  return (
    <Flex w="100vw" h="100vh" align="center" justify="center">
      <title>RN-ACOLHE - Redefinir senha</title>

      {showFormCode.current && (
        <Flex
          as="form"
          ref={formRef}
          w="100%"
          maxW="360px"
          bg={'platinum.100'}
          padding="8"
          borderRadius="8"
          flexDir="column"
          onSubmit={handleSubmitRequestCode(handleRequestCode)}
        >
          <Flex align={'center'} flexDir={'column'}>
            <Image h="4rem" src={LogoRNAcolhe} alt="Logo do RN Acolhe" />

            <Divider my={'2'} borderColor={'gray.700'} />

            <Text fontSize={'lg'} fontWeight={'bold'} color={'#1F191A'}>
              Redefinir senha
            </Text>
          </Flex>

          <Stack spacing={4}>
            <Input
              label={'CPF'}
              type={'text'}
              mask={'999.999.999-99'}
              placeholder="000.000.000-00"
              error={formStateRequestCode.errors.cpf}
              {...registerRequestCode('cpf')}
            />

            <Button
              type={'submit'}
              marginTop={6}
              bgColor={'green.50'}
              _hover={{ bgColor: 'green.100' }}
              color={'platinum.50'}
              size={'md'}
              isLoading={formStateRequestCode.isSubmitting}
              loadingText={'Solicitando'}
            >
              Solicitar
            </Button>

            <Divider my={'2'} borderColor={'gray.700'} />

            <Link to={'/'}>
              <ChakraLink
                display="flex"
                alignItems="center"
                justifyContent="center"
                minWidth="fit-content"
                rounded="md"
                fontWeight="bold"
                color={'platinum.50'}
                bgColor={'platinum.600'}
                _hover={{ bgColor: 'gray.200' }}
                px="4"
                py="2"
              >
                Cancelar
              </ChakraLink>
            </Link>
          </Stack>

          <Divider my={'4'} borderColor={'gray.700'} />

          <Flex align={'center'} justify={'center'}>
            <Image
              h="4rem"
              src={LogoSethas}
              alt="Logo da Secretaria de Estado do Trabalho, da Habitação e da Assistência Social (SETHAS)"
            />
          </Flex>
        </Flex>
      )}

      {showFormPassword.current && (
        <Flex
          as="form"
          ref={formRef}
          w="100%"
          maxW="360px"
          bg={'platinum.100'}
          padding="8"
          borderRadius="8"
          flexDir="column"
          onSubmit={handleSubmitRedefinePassword(handleRedefinePassword)}
        >
          <Flex align={'center'} flexDir={'column'}>
            <Image h="4rem" src={LogoRNAcolhe} alt="Logo do RN Acolhe" />

            <Divider my={'2'} borderColor={'gray.700'} />

            <Text fontSize={'lg'} fontWeight={'bold'} color={'#1F191A'}>
              Redefinir senha
            </Text>
          </Flex>

          <Stack spacing={4}>
            <Input
              disabled
              label={'CPF'}
              type={'text'}
              mask={'999.999.999-99'}
              placeholder="000.000.000-00"
              value={watchRequestCode('cpf')}
              error={formStateRedefinePassword.errors.cpf}
              {...registerRedefinePassword('cpf')}
            />

            <Input
              label={'Código'}
              type={'text'}
              placeholder="Código"
              error={formStateRedefinePassword.errors.codigo}
              {...registerRedefinePassword('codigo')}
            />

            <Input
              label={'Nova senha'}
              type="password"
              placeholder="Digite sua nova senha"
              error={formStateRedefinePassword.errors.nova_senha}
              {...registerRedefinePassword('nova_senha')}
            />
            <Input
              label={'Confirmar senha'}
              type="password"
              placeholder="Confirme sua nova senha"
              error={formStateRedefinePassword.errors.confirmar_nova_senha}
              {...registerRedefinePassword('confirmar_nova_senha')}
            />

            <Button
              type={'submit'}
              marginTop={6}
              bgColor={'green.50'}
              _hover={{ bgColor: 'green.100' }}
              color={'platinum.50'}
              size={'md'}
              isLoading={formStateRedefinePassword.isSubmitting}
              loadingText={'Confirmando'}
            >
              Confirmar
            </Button>

            <Divider my={'2'} borderColor={'gray.700'} />

            <Link to={'/'}>
              <ChakraLink
                display="flex"
                alignItems="center"
                justifyContent="center"
                minWidth="fit-content"
                rounded="md"
                fontWeight="bold"
                color={'platinum.50'}
                bgColor={'platinum.600'}
                _hover={{ bgColor: 'gray.200' }}
                px="4"
                py="2"
              >
                Cancelar
              </ChakraLink>
            </Link>
          </Stack>

          <Divider my={'4'} borderColor={'gray.700'} />

          <Flex align={'center'} justify={'center'}>
            <Image
              h="4rem"
              src={LogoSethas}
              alt="Logo da Secretaria de Estado do Trabalho, da Habitação e da Assistência Social (SETHAS)"
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
