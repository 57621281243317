import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react'

interface TabsComponentProps {
  tabsHeader: string[]
  tabsContent: any[]
}

export function TabsComponent({ tabsHeader, tabsContent }: TabsComponentProps) {
  return (
    <>
      <Tabs isFitted variant="enclosed">
        <TabList>
          {tabsHeader.map(label => (
            <Tab>{label}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabsContent.map(content => (
            <TabPanel>{content}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  )
}
