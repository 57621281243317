import { AgentCrasData, CreateAgentCrasFormData, CreateInstitutionFormData, InstitutionData } from "../pages/types"

export function addFormattingForCPF(cpf: string) {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export function addFormattingForNIS(nis: string) {
  return nis.replace(/(\d{3})(\d{5})(\d{2})(\d{1})/, '$1.$2.$3-$4')
}

export function addFormattingPrice(price: number) {
  return price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

export function addFormattingForDate(date: string) {
  return new Date(date).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  })
}

export function addFormattingForPhone(phone: string) {
  return phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
}

export function addFormattingForRG(rg: string) {
  return rg.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4')
}

export function addFormattingForCertificate(certificate: string) {
  return certificate.replace(
    /(\d{6})(\d{2})(\d{2})(\d{4})(\d{1})(\d{5})(\d{3})(\d{7})(\d{2})/,
    '$1 $2 $3 $4 $5 $6 $7 $8-$9'
  )
}

export function addFormattingForCNPJ(cnpj: string) {
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

export function formatInstitutionCreateData(values: CreateInstitutionFormData) {

  const institution: InstitutionData = {
    endereco: {
      logradouro: values.logradouro,
      numero: values.numero,
      bairro: values.bairro,
      complemento: values.complemento,
      ponto_de_referencia: values.ponto_de_referencia,
      uf: values.uf,
      cep: values.cep,
      municipio: values.municipio
    },
    nome: values.nome,
    ativa: true,
    cnpj: values.cnpj,
    email: values.email,
    telefone: values.telefone,
    natureza: values.natureza,
    modalidade: values.modalidade
  }
  return institution
}


export function formatAgentCrasCreateData(values: CreateAgentCrasFormData) {

  const AgentCras: AgentCrasData = {
    user: {
      username: values.cpf,
      password: values.password,
    },
    
    endereco: {
      logradouro: values.logradouro,
      numero: values.numero,
      bairro: values.bairro,
      complemento: values.complemento,
      ponto_de_referencia: values.ponto_de_referencia,
      uf: values.uf,
      cep: values.cep,
      municipio: Number(values.municipio)
    },
    nome: values.nome,
    data_nascimento: values.data_nascimento,
    sexo: Number(values.sexo),
    cpf: values.cpf,
    rg: values.rg,
    data_expedicao: values.data_expedicao,
    orgao_expedidor: values.orgao_expedidor,
    uf_orgao_expedidor: values.uf_orgao_expedidor,
    email: values.email,
    telefone: values.telefone,
    telefone_alternativo: values.telefone_alternativo,
    nome_profissao: values.nome_profissao,
    numero_registro: values.numero_registro,
    municipios: values.municipios,
  }
  return AgentCras
}


export enum StatusTypes {
  DOCUMENTACAO_PENDENTE = 'DOCUMENTAÇÃO PENDENTE',
  CADASTRADA = 'CADASTRADA',
  EM_ANALISE = 'EM ANÁLISE',
  DEFERIDA = 'DEFERIDA',
  INDEFERIDA = 'INDEFERIDA',
  EM_CORRECAO = 'EM CORREÇÃO',
  CORRIGIDA = 'CORRIGIDA'
}
