import { Box, Text, Stack } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface IPropsBaseCard {
  title: string
  subtitle: string
  children: ReactNode
}

export function BaseCard({ title, subtitle, children }: IPropsBaseCard) {
  return (
    <>
      <Box
        rounded={'8'}
        bgColor={'platinum.100'}
        overflow={'hidden'}
        boxShadow={'2px 2px 4px rgba(0, 0, 0, 0.25)'}
      >
        <Box p={4}>
          <Stack align={'flex-start'}>
            <Text as="h2" fontWeight="bold" color={'blue.500'}>
              {title}
            </Text>
            <Text fontWeight="light">{subtitle}</Text>
          </Stack>
          {children}
        </Box>
      </Box>
    </>
  )
}
