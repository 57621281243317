import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  SimpleGrid,
} from '@chakra-ui/react'
import { Input } from '../Form/Input'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

interface IPropsConfirmModal {
  keyMsg: string
  title: string
  msg: string
  isOpen: boolean
  statusId: number
  hasJustification?: boolean
  submitEvaluatedRequest: (
    statusId: number,
    parecer: string | undefined,
  ) => void
  toggle: () => void
}

interface IForm {
  hasParecer: string
  keyMsgInput: string
  parecer: string
}

const ConfirmModalSchema = Yup.object().shape({
  hasParecer: Yup.string().required(),
  keyMsgInput: Yup.string().required('Campo obrigatório'),
  parecer: Yup.string().when('hasParecer', {
    is: 'true',
    then: Yup.string().required('Parecer é obrigatório').trim(),
  }),
})

export function ConfirmModal({
  keyMsg,
  title,
  msg,
  isOpen,
  statusId,
  submitEvaluatedRequest,
  toggle,
  hasJustification = false,
}: IPropsConfirmModal) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm<IForm>({ resolver: yupResolver(ConfirmModalSchema) })

  const handleCloseModal = () => {
    toggle()
    reset()
  }

  const submit = (data: IForm) => {
    const { keyMsgInput, parecer } = data
    if (keyMsgInput === keyMsg) {
      submitEvaluatedRequest(statusId, parecer)
      reset()
    } else {
      setError('keyMsgInput', {
        type: 'manual',
        message: 'A palavra informada é inválida',
      })
    }
  }

  const actionNames = (statusId: number) => {
    switch (statusId) {
      case 4:
        return 'solicitar a correção da solicitação'
      case 6:
        return 'deferir a solicitação'
      case 7:
        return 'indeferir a solicitação'
      default:
        return 'confirmar'
    }
  }

  return (
    <>
      <Modal
        isCentered
        onClose={handleCloseModal}
        isOpen={isOpen}
        motionPreset="none"
        size={'lg'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent as={'form'} onSubmit={handleSubmit(submit)}>
          <ModalHeader textAlign={['center']}>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={2}>{msg}</Text>
            {/* Campo invisível utilizado para lógica de validação do YUP pra quando for informado se existe o campo parecer ou não */}
            <Input
              type={'hidden'}
              value={hasJustification ? 'true' : 'false'}
              {...register('hasParecer')}
            />
            <SimpleGrid
              minChildWidth={'244px'}
              spacing={['6', '8']}
              w={'100%'}
              mb={2}
            >
              <Input
                label={`Por favor, digite "${keyMsg}" para ${actionNames(
                  statusId,
                )}`}
                type={'text'}
                placeholder={'Insira a palavra para confirmar'}
                {...register('keyMsgInput')}
                size={'md'}
                error={errors.keyMsgInput}
              />
            </SimpleGrid>
            {hasJustification ? (
              <SimpleGrid
                minChildWidth={'244px'}
                spacing={['6', '8']}
                w={'100%'}
              >
                <Input
                  type={'text'}
                  label={`Informe o parecer para ${actionNames(statusId)}`}
                  as={'textarea'}
                  placeholder={'Insira o parecer da avaliação...'}
                  {...register('parecer')}
                  error={errors.parecer}
                  minH={'130px'}
                  size={'md'}
                  px="4"
                  py="2"
                />
              </SimpleGrid>
            ) : (
              ''
            )}
          </ModalBody>
          <ModalFooter justifyContent={['center']} gap="4">
            <Button
              size={'md'}
              color={'platinum.50'}
              bgColor={'platinum.600'}
              type="button"
              onClick={handleCloseModal}
            >
              Cancelar
            </Button>

            <Button
              size={'md'}
              color={'platinum.50'}
              bgColor={'green.50'}
              _hover={{ bgColor: 'green.100' }}
              type="submit"
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
