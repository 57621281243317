import { Text, ChakraProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface TextItemProps extends ChakraProps {
  label?: string
  children: ReactNode
}

export function TextItem({
  color = 'black.900',
  fontWeight = 'normal',
  label,
  children,
}: TextItemProps) {
  if (label) {
    return (
      <Text as="strong">
        {label}:
        <Text as="span" fontWeight={fontWeight} color={color} ml="1">
          {children}
        </Text>
      </Text>
    )
  }

  return (
    <Text as="strong" color={color}>
      {children}
    </Text>
  )
}
