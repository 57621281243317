import * as React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from './styles/theme'
import { SidebarDrawerProvider } from './contexts/SidebarDrawerContext'
import { Routes } from './routes/'

import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { queryClient } from './services/queryClient'
import { Toast } from './components/Toast'
import { AuthProvider } from './contexts/AuthContext'

const isDev = process.env.NODE_ENV === 'development'

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <SidebarDrawerProvider>
          <Router>
            <Routes />
          </Router>
        </SidebarDrawerProvider>
      </ChakraProvider>
      <Toast />
      {isDev && <ReactQueryDevtools initialIsOpen={false} />}
    </AuthProvider>
  </QueryClientProvider>
)
