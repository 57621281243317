import {
  Button,
  Flex,
  Heading,
  Icon,
  Spinner,
  SimpleGrid
} from "@chakra-ui/react";
import { useState } from 'react';
import { Pagination } from '../../components/Pagination'
import { Content } from "../../components/Content";
import { BreadCrumb } from "../../components/BreadCrumb";
import { Link } from "react-router-dom";
import { RiAddLine } from "react-icons/ri";
import { CardAgentCras } from "./components/CardAgentCras";
import { useAuth } from '../../contexts/AuthContext'
import { useAgentCras } from './hook/useAgentCras';
import { AlertComponent } from "../../components/Alert";

export function AgentCrasList() {

  const { user } = useAuth()
  const [page, setPage] = useState(1)
  const registersPerPage = 9

  const { data, isLoading, error, isFetching } = useAgentCras(
    page,
    registersPerPage,
    user && user.agente_id,
  )

  function showPagination(count: number) {
    return count > 9 && registersPerPage === 9
  }

  return (
    <Content>
      <title>RN-ACOLHE - Agentes CRAS</title>
      <BreadCrumb currentPath="Agente CRAS" />
      <Flex align={'center'} justify={'space-between'} mb={'8'}>
        <Heading size={'lg'} fontWeight={'normal'}>
          Agente CRAS
          {!isLoading && isFetching && (
              <Spinner size={'sm'} color={'blue.300'} ml={'4'} />
          )}
        </Heading>
        <Link to={'/agent-cras/create'}>
          <Button as={'a'}
            size={'sm'}
            fontSize={'sm'}
            color={'platinum.50'}
            bg={'green.50'}
            _hover={{ bg: 'green.100' }}
            leftIcon={<Icon as={RiAddLine} fontSize={'20'} ></Icon>}
          >
            Cadastrar agente CRAS
          </Button>
        </Link>
      </Flex>

      {isLoading ? (
        <Flex color={'blue.300'} justify={'center'} align={'center'}>
          <Spinner />
        </Flex>
      ) : error ? (
        <AlertComponent
          status={'error'}
          title={'Ocorreu um erro'}
          description={'Não foi possível listar as instituições'}
        />
      ) : data?.results.length === 0 ? (
        <AlertComponent
          status={'warning'}
          title={'Instituições não encontradas'}
          description={
            'Não foi possível encontrar instituições vinculadas ao profissional'
          }
        />
      ) : (
        <>
          <SimpleGrid flex={'1'} gap={'4'} minChildWidth={'320px'}>
            {data?.results.map(agentes => (
              <CardAgentCras
                id={agentes.id}
                nome={agentes.nome.toUpperCase()}
                cpf={agentes.cpf}
                profissao={agentes.nome_profissao}
                municipios={agentes.municipios}
              />
            ))}
          </SimpleGrid>
          
          {data && data?.results.length !== undefined && 
            data?.count > 9 && (
              <Pagination
                totalCountOfRegisters={data?.count}
                titlePage={'Agente CRAS'}
                currentPage={page}
                onPageChange={setPage}
                registersPerPage={registersPerPage}
              />
            )}
        </>
      )}
    </Content>
  )
}
