import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  VStack,
  Text,
  Checkbox,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { WarningTwoIcon } from '@chakra-ui/icons'
import { CreateAgentCrasFormData, AgentCrasData, Errors } from '../types'
import { CreateAgentCrasFormSchema } from '../schemas/createAgentCrasForm'
import { BreadCrumb } from '../../components/BreadCrumb'
import { Content } from '../../components/Content'
import { Input } from '../../components/Form/Input'
import { api } from '../../services/axios'
import { getCities, getDistricts } from '../../services/hooks'
import { formatAgentCrasCreateData } from '../../utils'
import { queryClient } from '../../services/queryClient'

export function CreateAgentCras() {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    setError,
    watch,
    resetField,
    formState: { errors, isSubmitting },
  } = useForm<CreateAgentCrasFormData>({
    resolver: yupResolver(CreateAgentCrasFormSchema),
  })

  const createAgentCras = useMutation(
    async (AgentCrasData: AgentCrasData) => {
      const { data } = await api.post('agentescras/', AgentCrasData)
      toast.success(`${data.mensagem}`)
    },
    { onSuccess, onError },
  )

  function onSuccess() {
    queryClient.invalidateQueries('agentecras')
    navigate('/agent-cras')
  }

  function onError(error: any) {
    const data = error?.response.data
    const requestErrors = Object.entries(data).map(([key, value]) => ({
      type: 'manual',
      name: key,
      message: `${value}`,
    }))
    if (requestErrors.length) {
      requestErrors.forEach(({ type, name, message }: Errors) => {
        setError(name, { type, message })
      })
    }
    toast.error(
      'Erro ao tentar cadastrar um agente CRAS. Verifique os dados informados e tente novamente!',
    )
  }

  const handleCreateAgentCras: SubmitHandler<CreateAgentCrasFormData> = async (
    values,
    event,
  ) => {
    event?.preventDefault()

    const listMunicipios = values.municipios
    const municipios = listMunicipios.map(Number)

    values.municipios = municipios

    if (values.nome_profissao === 'outros') {
      values.nome_profissao = values.profissao
    }

    // await new Promise((resolve) => setTimeout(resolve, 2000))
    const AgentCrasData = formatAgentCrasCreateData(values)

    await createAgentCras.mutateAsync(AgentCrasData)
  }

  const hasProfessionIsOthers = watch('nome_profissao') === 'outros'

  useEffect(() => {
    if (!hasProfessionIsOthers) {
      resetField('profissao', { defaultValue: '' })
    }
  }, [hasProfessionIsOthers, resetField])

  return (
    <Content>
      <Box as={'form'} onSubmit={handleSubmit(handleCreateAgentCras)}>
        <title>RN-ACOLHE - Cadastrar AgenteCRAS</title>
        <BreadCrumb
          PreviousPaths={[{ name: 'Agente CRAS', url: '/agent-cras' }]}
          currentPath="Cadastrar agente CRAS"
        />
        <Heading size={'lg'} fontWeight={'normal'}>
          Cadastrar agente CRAS
        </Heading>

        <Divider my={'6'} borderColor={'gray.700'} />

        <VStack>
          <Heading size={'md'} fontWeight={'normal'}>
            Dados Pessoais
          </Heading>

          <SimpleGrid minChildWidth={'244px'} w={'100%'}>
            <Input
              label={'Nome completo'}
              type={'text'}
              {...register('nome')}
              error={errors.nome}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'Data de nascimento'}
              type={'date'}
              {...register('data_nascimento')}
              error={errors.data_nascimento}
            />
            <Input
              as={'select'}
              label="Sexo"
              type={'text'}
              {...register('sexo')}
              error={errors.sexo}
            >
              <option value="">--Selecione--</option>
              <option value={1}>Feminino</option>
              <option value={2}>Masculino</option>
            </Input>
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'CPF'}
              type={'text'}
              mask={'999.999.999-99'}
              placeholder="000.000.000-00"
              {...register('cpf')}
              error={errors.cpf}
            />
            <Input
              label={'RG'}
              type={'text'}
              mask={'99.999.999-9'}
              placeholder="00.000.000-0"
              {...register('rg')}
              error={errors.rg}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'Órgão expedidor do RG'}
              type={'text'}
              {...register('orgao_expedidor')}
              error={errors.orgao_expedidor}
            />
            <Input
              as={'select'}
              label="UF do órgão expedidor do RG"
              {...register('uf_orgao_expedidor')}
              error={errors.uf_orgao_expedidor}
            >
              <option value="">--Selecione--</option>
              {getDistricts().map((district) => (
                <option key={district.id + 1} value={district.acronym}>
                  {district.name}
                </option>
              ))}
            </Input>
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'Data da expedição do RG'}
              type={'date'}
              {...register('data_expedicao')}
              error={errors.data_expedicao}
            />
            <Input
              label={'E-mail'}
              type={'email'}
              {...register('email')}
              error={errors.email}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'Telefone/Celular'}
              type={'text'}
              mask={'(99) 99999-9999'}
              placeholder="(00) 00000-0000"
              {...register('telefone')}
              error={errors.telefone}
            />
            <Input
              label={'Telefone/Celular alternativo'}
              type={'text'}
              mask={'(99) 99999-9999'}
              placeholder="(00) 00000-0000"
              _required={false}
              {...register('telefone_alternativo')}
              error={errors.telefone_alternativo}
            />
          </SimpleGrid>

          <Divider borderColor={'gray.700'} p={'4'} />
          <Heading size={'md'} fontWeight={'normal'} p={'4'}>
            Dados profissionais
          </Heading>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              as="select"
              label={'Nome da profissão'}
              type={'text'}
              {...register('nome_profissao')}
              error={errors.nome_profissao}
            >
              <option value="">--Selecione--</option>
              <option value="Psicologo">Psicologo</option>
              <option value="Advogado">Advogado</option>
              <option value="Assistente social">Assistente social</option>
              <option value="outros">Outros</option>
            </Input>
            {hasProfessionIsOthers && (
              <Input
                label={'Digite o nome da profissão'}
                type={'text'}
                {...register('profissao')}
                error={errors.profissao}
              />
            )}
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'Número de registro'}
              type={'text'}
              mask="99999999999999999999999999999999999999999999999999"
              {...register('numero_registro')}
              error={errors.numero_registro}
            />
            <Box>
              <Text fontWeight="medium">
                Município(s) de atuação
                <Text as="span" marginLeft={'0.2rem'} color="red.500">
                  *
                </Text>
              </Text>
              <VStack
                align="flex-start"
                overflow="auto"
                my="2"
                rounded="6"
                border="2px"
                _hover={{ borderColor: 'blue.300', height: '100px' }}
                height="40px"
                bgColor={'platinum.200'}
              >
                {getCities().map((city) => (
                  <Input
                    as={Checkbox}
                    type="checkbox"
                    border="none"
                    rounded="none"
                    mt="-1"
                    mb="-1"
                    key={city.id + 2}
                    value={city.id}
                    {...register('municipios')}
                  >
                    {city.name}
                  </Input>
                ))}
              </VStack>
              {errors.municipios && (
                <Text as="span" color="red.500" fontSize="md" pt="5">
                  <WarningTwoIcon mt={-1.5} mr={1} color={'red.500'} />
                  {errors.municipios.message}
                </Text>
              )}
            </Box>
          </SimpleGrid>

          <Divider my={'6'} borderColor={'gray.700'} p={'4'} />
          <Heading size={'md'} fontWeight={'normal'} p={'4'}>
            Dados endereço
          </Heading>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'CEP'}
              type={'text'}
              mask={'*****-***'}
              placeholder="00000-000"
              {...register('cep')}
              error={errors.cep}
            />
            <Input
              as={'select'}
              label={'Município'}
              type={'text'}
              {...register('municipio')}
              error={errors.municipio}
            >
              <option value="">--Selecione--</option>
              {getCities().map((city) => (
                <option key={city.id + 3} value={city.id}>
                  {city.name}
                </option>
              ))}
            </Input>
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'Logradouro'}
              type={'text'}
              {...register('logradouro')}
              error={errors.logradouro}
            />
            <Input
              label={'Número'}
              type={'text'}
              {...register('numero')}
              error={errors.numero}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'Bairro'}
              type={'text'}
              {...register('bairro')}
              error={errors.bairro}
            />
            <Input
              disabled
              label={'UF'}
              type={'text'}
              value={'RN'}
              {...register('uf')}
              error={errors.uf}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'Complemento'}
              type={'text'}
              _required={false}
              {...register('complemento')}
              error={errors.complemento}
            />
            <Input
              label={'Ponto de referência'}
              type={'text'}
              _required={false}
              {...register('ponto_de_referencia')}
              error={errors.ponto_de_referencia}
            />
          </SimpleGrid>

          <Divider my={'6'} borderColor={'gray.700'} p={'4'} />
          <Heading size={'md'} fontWeight={'normal'} p={'4'}>
            Dados de acesso
          </Heading>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'Senha'}
              type="password"
              {...register('password')}
              error={errors.password}
            />
            <Input
              label={'Confirmação da senha'}
              type="password"
              {...register('confirmation_password')}
              error={errors.confirmation_password}
            />
          </SimpleGrid>
        </VStack>

        <Flex mt={'8'} justify={'flex-end'}>
          <HStack spacing={'4'}>
            <Link to={'/agent-cras'}>
              <Button color={'platinum.50'} bgColor={'platinum.600'}>
                Cancelar
              </Button>
            </Link>
            <Button
              type="submit"
              size={'md'}
              bgColor={'blue.50'}
              _hover={{ bgColor: 'blue.200' }}
              color={'platinum.50'}
              isLoading={isSubmitting}
              loadingText={'Salvando'}
            >
              Salvar
            </Button>
          </HStack>
        </Flex>
      </Box>
    </Content>
  )
}
