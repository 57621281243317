import * as yup from 'yup'

export const SignInFormSchema = yup.object().shape({
  cpf: yup
    .string()
    .required('CPF é obrigatório')
    .transform(value => value.replace(/\D/g, ''))
    .matches(/^[0-9]+$/, 'CPF deve conter apenas números')
    .min(11, 'CPF deve ter no mínimo 11 caracteres')
    .max(11, 'CPF deve ter no máximo 11 caracteres'),
    password: yup
    .string()
    .required('Senha obrigatória')
})
