import {
  Heading,
  SimpleGrid,
  Box,
  Divider,
  VStack,
} from "@chakra-ui/react";
import { Content } from "../../components/Content";
import { BreadCrumb } from "../../components/BreadCrumb";
import { useAuth } from '../../contexts/AuthContext'
import { useGetAgenteCras } from "./hook";
import { Input } from "../../components/Form/Input";
import { useParams } from "react-router-dom";

export function AgentCrasDetail() {


  const { user } = useAuth()
  const agenteId = user && user.agente_id
  const {agenteCrasId} = useParams()
  const agenteCras = useGetAgenteCras(agenteId, Number(agenteCrasId))

  return (
    <Content>
      <Box as={'form'}>
        <title>RN-ACOLHE - Detalhar agente CRAS</title>
        <BreadCrumb
          PreviousPaths={[{ name: 'Agente CRAS', url: '/agent-cras' }]}
          currentPath="Detalhar agente CRAS"
        />
        <Heading size={'lg'} fontWeight={'normal'}>
          Detalhar agente CRAS
        </Heading>

        <Divider my={'6'} borderColor={'gray.700'} />

        <VStack>
          <Heading size={'md'} fontWeight={'normal'}>
            Dados Pessoais
          </Heading>

          <SimpleGrid minChildWidth={'244px'} w={'100%'}>
            <Input
              label={'Nome completo'}
              type={'text'}
              name={'nome'}
              value={agenteCras?.nome}
              isReadOnly
              _required={false}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'Data de nascimento'}
              type={'date'}
              name={'data_nascimento'}
              value={agenteCras?.data_nascimento}
              isReadOnly
              _required={false}
            />
            <Input
              label={'Sexo'}
              type={'text'}
              name={'sexo'}
              value={agenteCras?.sexo === 1 ? 'Feminino' : 'Masculino'}
              isReadOnly
              _required={false}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'CPF'}
              type={'text'}
              name={'cpf'}
              mask={'999.999.999-99'}
              value={agenteCras?.cpf}
              isReadOnly
              _required={false}
            />
            <Input
              label={'RG'}
              type={'text'}
              mask={'99.999.999-9'}
              name={'rg'}
              value={agenteCras?.rg}
              isReadOnly
              _required={false}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'Órgão expedidor do RG'}
              type={'text'}
              name={'orgao_expedidor_rg'}
              value={agenteCras?.orgao_expedidor}
              isReadOnly
              _required={false}
            />
            <Input
              label={'UF do órgão expedidor do RG'}
              type={'text'}
              name={'uf_orgao_expedidor_rg'}
              value={agenteCras?.uf_orgao_expedidor}
              isReadOnly
              _required={false}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'Data da expedição do RG'}
              type={'date'}
              name={'data_expedicao_rg'}
              value={agenteCras?.data_expedicao}
              isReadOnly
              _required={false}
            />
            <Input
              label={'E-mail'}
              type={'email'}
              name={'email'}
              value={agenteCras?.email}
              isReadOnly
              _required={false}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'Telefone/Celular'}
              type={'text'}
              mask={'(99) 99999-9999'}
              name={'telefone'}
              value={agenteCras?.telefone}
              isReadOnly
              _required={false}
            />
            <Input
              label={'Telefone/Celular Alternativo'}
              type={'text'}
              mask={'(99) 99999-9999'}
              name={'telefone_alternativo'}
              value={agenteCras?.telefone_alternativo}
              isReadOnly
              _required={false}
            />
          </SimpleGrid>

          <Divider p={'4'} borderColor={'gray.700'} />

          <Heading size={'md'} fontWeight={'normal'} p={'4'}>
            Dados profissionais
          </Heading>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'Nome da Profissão'}
              type={'text'}
              name={'nome_profissao'}
              value={agenteCras?.nome_profissao}
              isReadOnly
              _required={false}
            />
            <Input
              label={'Número de registro'}
              type={'text'}
              name={'numero_registro'}
              value={agenteCras?.numero_registro}
              isReadOnly
              _required={false}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              p={'4'}
              minHeight={'85px'}
              as={'textarea'}
              label={'Município(s) de atuação'}
              type={'text'}
              name={'municipios'}
              value={agenteCras?.municipios.join(', ') + '.'}
              isReadOnly
              _required={false}
            />
          </SimpleGrid>

          <Divider p={'4'} borderColor={'gray.700'} />

          <Heading size={'md'} fontWeight={'normal'} p={'4'}>
            Dados do Endereço
          </Heading>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'Logradouro'}
              type={'text'}
              name={'logradouro'}
              value={agenteCras?.endereco.logradouro}
              isReadOnly
              _required={false}
            />
            <Input
              label={'Número'}
              type={'text'}
              name={'numero'}
              value={agenteCras?.endereco.numero}
              isReadOnly
              _required={false}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'Bairro'}
              type={'text'}
              name={'bairro'}
              value={agenteCras?.endereco.bairro}
              isReadOnly
              _required={false}
            />
            <Input
              label={'UF'}
              type={'text'}
              name={'uf'}
              value={agenteCras?.endereco.uf}
              isReadOnly
              _required={false}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth={'244px'} spacing={['6', '8']} w={'100%'}>
            <Input
              label={'Complemento'}
              type={'text'}
              name={'complemento'}
              value={agenteCras?.endereco.complemento}
              isReadOnly
              _required={false}
            />
            <Input
              label={'Ponto de referencia'}
              type={'text'}
              name={'ponto_de_referencia'}
              value={agenteCras?.endereco.ponto_de_referencia}
              isReadOnly
              _required={false}
            />
          </SimpleGrid>

        </VStack>

      </Box>
    </Content>
  )
}